import { Component, Input, OnInit } from '@angular/core';

interface Status {
  title: string;
  message: string;
}
@Component({
  selector: 'profile-task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.scss'],
})
export class TaskStatusComponent implements OnInit {
  @Input() status: Status;
  @Input() rejectedReason: string;

  public showStatusMessage: boolean = false;

  ngOnInit(): void {
    this.status = this.status || {
      title: 'Incomplete',
      message: '',
    };
  }

  public onMessageClick($event: any): void {
    this.showStatusMessage = !this.showStatusMessage;
    $event.stopPropagation();
  }

  public hideMessage(): void {
    this.showStatusMessage = false;
  }
}
