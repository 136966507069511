import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DescriptionComponent } from './description/description.component';
import { TitleComponent } from './title/title.component';

@NgModule({
  declarations: [TitleComponent, DescriptionComponent],
  imports: [CommonModule],
})
export class TypographyModule {}
