import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class CustomUrlSerializer extends DefaultUrlSerializer {
  override parse(url: string): UrlTree {
    let isCrossAppNavigation = url.includes('profile#/');
    let isNavToNg = url.match(/\/[a-zA-Z0-9\-_]+\/[a-zA-Z0-9\-_]+\/btp\/*/);
    if (!isCrossAppNavigation && isNavToNg) {
      return super.parse(url);
    } else {
      return super.parse(url.substring(url.indexOf('#') + 1));
    }
  }
}
