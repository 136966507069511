<div class="btp-profile-layout">
  <!-- ------------------------------- HEADER -------------------------------- -->
  <profile-header></profile-header>
  <div [ngClass]="uxService.navigationVisible ? 'btp-outlet-wrapper' : 'btp-outlet-wrapper-no-nav'">
    <!-- --------------------------- LOADING SPINNER --------------------------- -->
    <div class="spinner-wrapper" *ngIf="loading$ | async">
      <profile-loading-spinner></profile-loading-spinner>
    </div>
    <!-- ---------------------------- ROUTER OUTLET ---------------------------- -->
    <router-outlet></router-outlet>
  </div>
  <!-- ------------------------------ SIDE-NAV ------------------------------- -->
  <profile-side-nav></profile-side-nav>
  <!-- ------------------------------- TOASTS -------------------------------- -->
  <div class="btp-toasts">
    <ng-container *ngFor="let toast of toasts$ | async; index as i">
      <profile-toast [index]="i" [data]="toast"></profile-toast>
    </ng-container>
  </div>
</div>
