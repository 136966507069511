import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, map, Observable, of } from 'rxjs';
import { Cacheable } from 'ts-cacheable';
import { CompanyService } from '../../shared/services/company.service';
import { environment } from './../../../environments/environment';
import { SessionService } from './session.service';
import { ToastService } from './toast.service';
import { UxService } from './ux.service';

@Injectable({
  providedIn: 'root',
})
export class PrescreeningService {
  templateIds = [];
  templatesLoaded = false;

  constructor(
    private _httpClient: HttpClient,
    private sessionService: SessionService,
    private companyService: CompanyService,
    private toastService: ToastService,
    public uxService: UxService,
  ) {}

  @Cacheable({ maxAge: 5 * 60 * 1000 })
  public getPreScreeningTemplateIds(category: string): Observable<any> {
    this.uxService.loading();
    const url = `${environment.php_host}/api/customer/prescreening`;
    const params = { customerId: this.companyService.customerId, applicant_id: this.sessionService.profileApplicantId, is_applicant: 1 };
    return this._httpClient.get<any>(url, { params }).pipe(
      map((response: any) => {
        if (response?.data) {
          response.data.forEach((result) => {
            if (!this.templateIds.includes(result.template_id) && (result.category === category || parseInt(result.template_type_id) === 10)) {
              this.templateIds.push(result.template_id);
            }
          });
        }
        return this.templateIds;
      }),
      catchError((err) => {
        this.toastService.error('Something went wrong', 'Failed to load pre-screening.');
        return of(this.templateIds);
      }),
      finalize(() => this.uxService.loading(false)),
    );
  }
}
