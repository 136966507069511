<mat-card class="widget-card btp-shadow-low" [ngClass]="!taskIsClickable ? 'disabled' : ''" (click)="taskClicked(task)">
  <div style="flex: 1">
    <div class="title-wrapper">
      <div class="widget-card-title">
        {{ task.name }}
        <ng-container *ngIf="taskIsComplete && !!secondaryFileDownloadUrl">
          (<profile-translation key="credential_image_capture_front" defaultValue="Front"></profile-translation>)
        </ng-container>
      </div>
    </div>
    <div class="subtitle-wrapper">
      <div class="widget-card-subtitle">
        <i class="icon ri-file-list-3-line"></i>
        <profile-translation [key]="taskTypeTranslation[task.taskType]"></profile-translation>
      </div>
      <div class="widget-card-subtitle" *ngIf="!task.completedDate && task.expirationDate" [ngClass]="{ 'widget-card-subtitle--alert': task.expiresIn < 2 }">
        <ng-container *ngIf="task.expiresIn > 1; else alertInfoTemplate">
          <i class="icon ri-calendar-event-fill"></i>
          <span class="info">
            <profile-translation key="due"></profile-translation>
          </span>
          <span class="date">{{ task.expirationDate | date : dateFormat }}</span>
        </ng-container>
        <ng-template #alertInfoTemplate>
          <i class="icon ri-alert-fill"></i>
          <span class="info"> <profile-translation key="due"></profile-translation> <profile-translation *ngIf="task.expiresIn === 0" key="today"></profile-translation> </span>
          <span class="date">{{ task.expirationDate | date : dateFormat }}</span>
        </ng-template>
      </div>
      <div class="widget-card-subtitle widget-card-subtitle--success" *ngIf="task.completedDate">
        <i class="icon ri-checkbox-circle-fill"></i>
        <span class="info">
          <profile-translation key="completed"></profile-translation>
        </span>
        <span class="date">{{ task.completedDate | date : dateFormat }}</span>
      </div>
    </div>
  </div>
  <button *ngIf="showOpenTaskButton" mat-flat-button class="btp-btn-sm widget-action-btn" color="primary">
    <i class="ri-arrow-right-line"></i>
  </button>
  <button *ngIf="showPackageDetailsButton" mat-flat-button class="btp-btn-sm widget-action-btn" color="primary">
    <i class="ri-arrow-right-line"></i>
  </button>
  <button *ngIf="downloadAvailable" mat-flat-button class="btp-btn-sm widget-action-btn" color="primary">
    <i class="ri-download-line"></i>
  </button>
</mat-card>

<mat-card *ngIf="taskIsComplete && !!secondaryFileDownloadUrl" class="widget-card btp-shadow-low" (click)="secondaryFileDownload()">
  <div style="flex: 1">
    <div class="title-wrapper">
      <div class="widget-card-title">
        {{ task.name }}
        (<profile-translation key="credential_image_capture_back" defaultValue="Back"></profile-translation>)
      </div>
    </div>
    <div class="subtitle-wrapper">
      <div class="widget-card-subtitle">
        <i class="icon ri-file-list-3-line"></i>
        <profile-translation [key]="taskTypeTranslation[task.taskType]"></profile-translation>
      </div>
      <div class="widget-card-subtitle" *ngIf="!task.completedDate && task.expirationDate" [ngClass]="{ 'widget-card-subtitle--alert': task.expiresIn < 2 }">
        <ng-container *ngIf="task.expiresIn > 1; else alertInfoTemplate">
          <i class="icon ri-calendar-event-fill"></i>
          <span class="info">
            <profile-translation key="due"></profile-translation>
          </span>
          <span class="date">{{ task.expirationDate | date : dateFormat }}</span>
        </ng-container>
        <ng-template #alertInfoTemplate>
          <i class="icon ri-alert-fill"></i>
          <span class="info"> <profile-translation key="due"></profile-translation> <profile-translation *ngIf="task.expiresIn === 0" key="today"></profile-translation> </span>
          <span class="date">{{ task.expirationDate | date : dateFormat }}</span>
        </ng-template>
      </div>
      <div class="widget-card-subtitle widget-card-subtitle--success" *ngIf="task.completedDate">
        <i class="icon ri-checkbox-circle-fill"></i>
        <span class="info">
          <profile-translation key="completed"></profile-translation>
        </span>
        <span class="date">{{ task.completedDate | date : dateFormat }}</span>
      </div>
    </div>
  </div>
  <button *ngIf="downloadAvailable" mat-flat-button class="btp-btn-sm widget-action-btn" color="primary">
    <i class="ri-download-line"></i>
  </button>
</mat-card>
