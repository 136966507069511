import { Component, Input } from '@angular/core';

@Component({
  selector: 'profile-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss'],
})
export class ProgressCircleComponent {
  @Input() percent: number;
  @Input() color: string;
  @Input() diameter: number;
}
