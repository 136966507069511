import { NgModule } from '@angular/core';
import { FilterByPipe } from './filter-banner.pipe';
import { FilteredArrayLengthPipe } from './filtered-array-length.pipe';
import { SafePipe } from './safe-pipe';
import { TalentPlatformConfigPipe } from './tp-config.pipe';
import { SentenceCasePipe } from './sentence-case.pipe';

@NgModule({
  declarations: [FilteredArrayLengthPipe, TalentPlatformConfigPipe, SafePipe, FilterByPipe, SentenceCasePipe],
  exports: [FilteredArrayLengthPipe, TalentPlatformConfigPipe, SafePipe, FilterByPipe, SentenceCasePipe],
  providers: [FilteredArrayLengthPipe, TalentPlatformConfigPipe, SafePipe, FilterByPipe, SentenceCasePipe],
})
export class PipesModule { }
