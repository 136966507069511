<profile-sub-header actionTranslation="talent_portal_back_to_home" optionalRoute="../home"></profile-sub-header>

<!-- ----------------------------- MOBILE VIEW ----------------------------- -->

<ng-container *ngIf="isMobile$ | async; else desktop">
  <div class="mobile-container">
    <!-- --------------------------- HEADER CONTENT ---------------------------- -->
    <div class="header-wrapper" *ngIf="'enable_completed_tasks_view' | tpConfig; else openTasksView">
      <ng-container [ngTemplateOutlet]="headerContentTemplate"></ng-container>
    </div>
    <!-- ----------------------------- TASKS LIST ------------------------------ -->
    <div class="task-cards-container">
      <ng-container [ngTemplateOutlet]="tasksListTemplate"></ng-container>
    </div>
    <!-- ----------------------------- COMPLETED TASKS DETAILS LIST ------------------------------ -->
    <div class="completed-tasks-outlet-container filter-row-bump">
      <router-outlet name="completedTasksOutlet"></router-outlet>
    </div>
  </div>
</ng-container>

<!-- ---------------------------- DESKTOP VIEW ----------------------------- -->
<ng-template #desktop>
  <div class="flex-wrapper">
    <div class="tasks-container">
      <div class="page-container">
        <div class="page-section">
          <!-- <div class="desktop-container"> -->
          <!-- ------------------------- HEADER CONTENT CARD ------------------------- -->
          <div class="section-title">
            <mat-card class="header-contents-card" *ngIf="'enable_completed_tasks_view' | tpConfig; else openTasksView">
              <ng-container [ngTemplateOutlet]="headerContentTemplate"></ng-container>
            </mat-card>
          </div>
          <!-- ----------------------------- TASKS LIST ------------------------------ -->
          <div class="page-actions">
            <div class="task-cards-container btp-scroll">
              <ng-container [ngTemplateOutlet]="tasksListTemplate"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ------------------------- TASK DRAWER OUTLET -------------------------- -->

    <div class="completed-tasks-outlet-container filter-row-bump">
      <router-outlet name="completedTasksOutlet"></router-outlet>
    </div>
  </div>
</ng-template>

<!-- ----------------------------------------------------------------------- -->
<!--                         HEADER CONTENT TEMPLATE                         -->
<!-- ----------------------------------------------------------------------- -->
<ng-template #headerContentTemplate>
  <div class="header-contents">
    <div class="section-title">
      <profile-translation key="task_list_title"></profile-translation>
    </div>
    <!-- ---------------------------- SEARCH INPUT ----------------------------- -->
    <!-- <mat-form-field appearance="outline" class="search-form-field no-error">
      <input matInput placeholder="Search..." formControlName="searchInput" class="search-input" disabled="true" />
      <mat-icon matSuffix>
        <i class="ri-briefcase-4-line"></i>
      </mat-icon>
    </mat-form-field> -->
    <!-- -------------------------- TASK TYPE TOGGLE --------------------------- -->
    <mat-button-toggle-group class="btp-toggle-group" name="tasksToggle" (change)="configureTaskList($event.value)" aria-label="Tasks Toggle" [value]="taskCategoryDisplayed">
      <mat-button-toggle value="open" (click)="closeDetailsDrawerIfOpen()">
        <profile-translation key="talent_platform_taskspage_open_title"></profile-translation>
        ({{ !('disable_complete_profile_tsk_list' | tpConfig) && incompleteProfileStepsCount ? openTasks.length + 1 : openTasks.length }}
        )
      </mat-button-toggle>
      <mat-button-toggle value="completed">
        <profile-translation key="talent_platform_taskspage_completed_title"></profile-translation>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</ng-template>

<ng-template #openTasksView>
  <div class="section-title">
    <profile-translation key="task_list_title"></profile-translation>
    <span> ({{ !('disable_complete_profile_tsk_list' | tpConfig) && incompleteProfileStepsCount ? openTasks.length + 1 : openTasks.length }} ) </span>
  </div>
</ng-template>

<!-- ----------------------------------------------------------------------- -->
<!--                           TASKS LIST TEMPLATE                           -->
<!-- ----------------------------------------------------------------------- -->

<ng-template #tasksListTemplate>
  <profile-complete-profile-card
    *ngIf="isCompleteProfileCardVisible"
    [completenessPercentage]="percent"
    [incompleteStepsCount]="incompleteProfileStepsCount"
    [profileStepsCount]="profileSteps.length"
    (navigateToProfileEvent)="navigateToProfileSection()"
  ></profile-complete-profile-card>
  <profile-task-card
    *ngFor="let task of tasksDisplayed; let i = index"
    [taskCategory]="taskCategoryDisplayed"
    [task]="task"
    [dateFormat]="i18n.dateFormat"
    [taskIsFirstInTaskList]="i === 0"
    [completeProfileBeforeOnboarding]="completeProfileBeforeOnboarding"
    [enforceTaskListOrderEnabled]="tasksInChronologicalOrder"
    [numberOfIncompleteProfileSteps]="incompleteProfileStepsCount"
    (openTask)="openTask($event)"
    (openPackageInfo)="openCompletedTaskDetails($event)"
  ></profile-task-card>
</ng-template>
