import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, shareReplay } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, tap } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import { environment } from '../../../environments/environment';
import { Customer } from '../models/customer.model';
import { CustomI18n } from '../models/i18n.model';
import { LanguageDetail } from '../models/language-detail.model';
import { CustomerLocation } from '../models/location.model';
import {LocalStorageService} from "../../localStorage.service";

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  public i18n: CustomI18n;

  /* ----------------------------- GETTERS/SETTERS ---------------------------- */

  public get customerCode(): string {
    const url = new URL(location.href);
    const hashPathSplit = url.hash.split('/');
    const fromUrl = url.searchParams.get('customerCode') || hashPathSplit[1];
    const fromLocalStorage = this.localStorage.getItem('profile-customerCode');
    if (fromLocalStorage && fromUrl === fromLocalStorage) return fromLocalStorage;
    else this.customerCode = fromUrl;
    return fromUrl;
  }

  public set customerCode(v: string) {
    v ? this.localStorage.setItem('profile-customerCode', v) : this.localStorage.removeItem('profile-customerCode');
  }

  public get locationCode(): string {
    const url = new URL(location.href);
    const hashPathSplit = url.hash.split('/');
    const fromUrl = url.searchParams.get('locationCode') || hashPathSplit[2];
    const fromLocalStorage = this.localStorage.getItem('profile-locationCode');
    if (fromLocalStorage && fromUrl === fromLocalStorage) return fromLocalStorage;
    else this.locationCode = fromUrl;
    return fromUrl;
  }

  public set locationCode(v: string) {
    v ? this.localStorage.setItem('profile-locationCode', v) : this.localStorage.removeItem('profile-locationCode');
  }

  public get customerId(): string {
    return this.localStorage.getItem('profile-customerId');
  }

  public set customerId(v: string) {
    v ? this.localStorage.setItem('profile-customerId', v) : this.localStorage.removeItem('profile-customerId');
  }

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {}

  /* --------------------------- APP-INIT/RESOLVERS --------------------------- */

  // APP_INITIALIZER
  getCustomerId(): Observable<any> {
    const idFromStorage = this.localStorage.getItem('profile-customerId');
    if (idFromStorage) return of(idFromStorage);
    else {
      if (this.customerCode && this.customerCode !== '404') {
        const url = `${environment.php_host}/api/jobs/customer/${this.customerCode}`;
        return this.http.get(url).pipe(
          map((result) => result?.['data']?.customer_id),
          tap((id) => (this.customerId = id)),
        );
      }
    }
  }

  // RESOLVER
  @Cacheable({ maxAge: 60 * 60 * 1000 })
  getCustomer(): Observable<Customer> {
    const url = `${environment.php_host}/api/unauthprofile/customer?customer_code=${this.customerCode}`;
    return this.http.get<{ data; status }>(url).pipe(
      map((x) => {
        let customer: Customer = x.data;
        if (customer.country === 'Canada') customer.country = 'CA';
        return customer;
      }),
      shareReplay(),
    );
  }

  // RESOLVER
  @Cacheable({ maxAge: 60 * 60 * 1000 })
  getCustomDateFormat(): Observable<CustomI18n> {
    const locationCode = this.locationCode;
    const url = `${environment.applicant_core_api_host}/api/v1/InternationalFormats/date-formats/${this.customerId}/${locationCode}`;
    return this.http.get<CustomI18n>(url).pipe(
      map((result) => ({ ...result, dateFormat: result.dateFormat.replace(/D/g, 'd') })), // 'D' to 'd' for date pipe
      tap((result) => {
        this.i18n = result ?? { id: '', locale: '', dateFormat: 'M/d', timeFormat: '', description: '' };
        this.i18n.shortDate = this.i18n.dateFormat.substring(1, 4);
        // ID 4 Canadian is the only one that starts with the year
        if (this.i18n.id == '4') {
          this.i18n.shortDate = 'M-d';
        }
      }),
    );
  }

  /* ----------------------------------- // ----------------------------------- */

  @Cacheable({ maxAge: 60 * 60 * 1000 })
  getCompanyLogo(): Observable<string> {
    const url = `${environment.php_host}/api/branding/logo/${this.customerCode}/${this.locationCode}`;
    return this.http.get(url).pipe(map((logo: any) => (logo ? 'data:image/png;base64,' + logo.data : '')));
  }

  // RESOLVER
  @Cacheable({ maxAge: 60 * 60 * 1000 })
  getBrandingColors(): Observable<string[]> {
    const url = `${environment.php_host}/api/v2/customers/${this.customerId}/branding/colors`;
    const params = { customer_code: this.customerCode };
    return this.http.get(url, { params }).pipe(map((res: any) => res.data));
  }

  @Cacheable({ maxAge: 60 * 60 * 1000 })
  getCustomerStyles(): Observable<string[]> {
    const url = `${environment.php_host}/api/branding/profileCss/${this.customerCode}/${this.locationCode}`;
    return this.http.get(url).pipe(map((res: any) => res.data));
  }

  @Cacheable({ maxAge: 60 * 60 * 1000 })
  getLocation(): Observable<CustomerLocation> {
    const url = `${environment.applicant_core_api_host}/api/v1/location/${this.locationCode}`;
    return this.http.get<CustomerLocation>(url);
  }
  @Cacheable({ maxAge: 60 * 60 * 1000 })
  getLanguages(): Observable<LanguageDetail[]> {
    const url = `${environment.applicant_core_api_host}/api/v1/languages/${this.customerId}/${this.locationCode}`;
    return this.http.get<LanguageDetail[]>(url);
  }

  // TODO: BTP -- complete this method once all translation logic is moved over to Angular from AngularJS
  // getCompanyTranslations(): Observable<any> {
  //   const url = `${environment.php_host}/api/v1/customerContext/${this.customerCode}/translations`;
  //   return this.http.get(url).pipe(
  //     map((translations: any) => translations) // TODO: BTP
  //   );
  // }
}
