<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  style="display: block"
  width="100px"
  height="200px"
  viewBox="0 0 100 100"
  preserveAspectRatio="xMidYMid"
>
  <style>
    :root {
      --stroke-color: var(--primary-color);
    }
  </style>

  <circle cx="50" cy="50" r="0" fill="none" stroke="var(--stroke-color)" stroke-width="4">
    <animate attributeName="r" repeatCount="indefinite" dur="2.3s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="0s"></animate>
    <animate attributeName="opacity" repeatCount="indefinite" dur="2.3s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="0s"></animate>
  </circle>
  <circle cx="50" cy="50" r="0" fill="none" stroke="var(--stroke-color)" stroke-width="4">
    <animate attributeName="r" repeatCount="indefinite" dur="2.3s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="-1.2s"></animate>
    <animate attributeName="opacity" repeatCount="indefinite" dur="2.3s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="-1.2s"></animate>
  </circle>
</svg>
