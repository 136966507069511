<mat-card class="widget-card btp-shadow-low" (click)="navigateToProfileSection()">
  <profile-progress-circle [percent]="completenessPercentage" [diameter]="36"></profile-progress-circle>
  <div style="flex: 1">
    <div class="subtitle-wrapper">
      <div class="widget-card-title" style="margin-bottom: 4px">
        <profile-translation [key]="incompleteStepsCount === profileStepsCount ? 'task_list_action_start_profile' : 'complete_my_profile'"> </profile-translation>
      </div>
    </div>
    <div class="title-wrapper">
      <div class="widget-card-title">
        <profile-translation key="talent_platform_homepage_remaining_sections" [values]="[incompleteStepsCount, profileStepsCount]"></profile-translation>
      </div>
    </div>
  </div>
  <button mat-flat-button class="btp-btn-sm widget-action-btn" color="primary">
    <i class="ri-arrow-right-line"></i>
  </button>
</mat-card>
