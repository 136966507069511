import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { AngularJSResourceService } from 'src/profile-ng/app/core/services/angular-js-resource.service';

@Component({
  selector: 'profile-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent {
  @Input() tasks: any;
  @Input() total: number;
  @Input() showStatus: boolean = false;
  @Input() isMobile: boolean = false;
  @Output() page: EventEmitter<any> = new EventEmitter<any>();

  pageSize: number = 10;
  pageSizeOptions = [10, 20, 30];
  Session: any;

  constructor(private ngService: AngularJSResourceService) {
    this.Session = this.ngService?.getResource('Session');
  }

  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.isMobile = event?.target?.innerWidth < 768;
  }

  changePage($event: PageEvent) {
    this.page.emit($event);
  }
}
