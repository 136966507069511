import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreakpointObserverService {
  constructor(private breakpointObserver: BreakpointObserver) {}

  // TODO -- BTP: pull exact screen limit from a CONST file
  isMobile$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 768px)').pipe(
    map((v) => v.matches),
    shareReplay(),
  );
}
