import { Location, ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { BannerConfig } from '../../shared/models/banner-config';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private _banners = new BehaviorSubject<BannerConfig[]>([]);
  public banners$: Observable<BannerConfig[]> = this._banners.asObservable();

  constructor(private viewportScroller: ViewportScroller, private router: Router, private location: Location) {}

  scrollToTop(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  removeFragment() {
    const pathWithoutHash = this.router.url.split('#')[0];
    this.location.replaceState(pathWithoutHash);
  }

  addBanner(newBanner: BannerConfig) {
    const currentBanners = this._banners.value;
    const isAlreadyInBanners = currentBanners.some((banner) => banner.id === newBanner.id);
    if (!isAlreadyInBanners) {
      const updatedBanners = [newBanner, ...currentBanners];
      this.nextBanners(updatedBanners);
    }
  }

  removeBanner(bannerId: string) {
    const currentBanners = this._banners.value;
    const updatedBanners = currentBanners.filter((banner) => banner.id !== bannerId);
    this.nextBanners(updatedBanners);
  }

  nextBanners(banners: BannerConfig[]): void {
    this._banners.next(banners);
  }
}
