import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { YesOrNo } from 'src/profile-ng/app/shared/models/common.model';
import { CurrentTOSVersionBE } from '../../services/tos.service';

export interface TOSDialogResponse {
  currentTOSVersion: CurrentTOSVersionBE;
  userResponse: YesOrNo;
}

@Component({
  selector: 'profile-tos-modal',
  templateUrl: './tos-dialog.component.html',
  styleUrls: ['./tos-dialog.component.scss'],
})
export class TosDialogComponent {
  constructor(public dialogRef: MatDialogRef<TosDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CurrentTOSVersionBE) {}

  onClick(yesOrNo: YesOrNo): void {
    const dialogResponse: TOSDialogResponse = {
      currentTOSVersion: this.data,
      userResponse: yesOrNo,
    };
    this.dialogRef.close(dialogResponse);
  }
}
