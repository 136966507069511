export const environment = {
    production: true,
    // application
    php_host: "https://app.ableteams.com",
    applicant_core_api_host: "https://app-api.ableteams.com",
    customer_core_api_host: "https://app-customer.ableteams.com",
    i9_core_api_host: "https://app-i9.ableteams.com",
    poll_interval: 10,
    heartbeat_interval: 60,
    scroll_limit: 25,
    num_jobs_to_show: 8,
    apps_to_load: 1000,

    // hellosign
    hellosign_client_id: "77a0e56786aa120bc954996f3413b150",
    hellosign_client_id_insert_everywhere: "77a0e56786aa120bc954996f3413b150",
    hellosign_skip_domain_verification: true,

    // auth0
    auth0_client_url: "ableteams-prod.us.auth0.com",
    auth0_client_id: "pP30VfpvOVHwUilAXzir0Png5FF0hSFb",
    auth0_custom_url: "auth.ableteams.com",

    fullstory_enabled: true,
    newrelic_app_id: "566191736"
};
