import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../shared/modules/material/material.module';
import { BannerComponent } from './components/banner/banner.component';
import { BasicPageComponent } from './components/basic-page/basic-page.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { HeaderComponent } from './components/header/header.component';
import { HyperlinkCardComponent } from './components/hyperlink-card/hyperlink-card.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { MiniSpinnerComponent } from './components/mini-spinner/mini-spinner.component';
import { ProgressCircleComponent } from './components/progress-circle/progress-circle.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { SummaryCardListComponent } from './components/summary-card-list/summary-card-list.component';
import { SummaryCardComponent } from './components/summary-card/summary-card.component';
import { ToastComponent } from './components/toast/toast.component';
import { TosDialogComponent } from './components/tos-dialog/tos-dialog.component';
import { TranslationComponent } from './components/translation/translation.component';
import { UnsavedChangesComponent } from './components/unsaved-changes/unsaved-changes.component';
import { BtpProgressDirective } from './directives/btp-progress.directive';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
  imports: [CommonModule, MaterialModule, MatPaginatorModule, RouterModule, PipesModule, ScrollingModule, ReactiveFormsModule],
  declarations: [
    HeaderComponent,
    SideNavComponent,
    LayoutComponent,
    BtpProgressDirective,
    MiniSpinnerComponent,
    DropdownComponent,
    ToastComponent,
    BannerComponent,
    HyperlinkCardComponent,
    SubHeaderComponent,
    LoadingSpinnerComponent,
    ProgressCircleComponent,
    BasicPageComponent,
    TranslationComponent,
    SummaryCardComponent,
    SummaryCardListComponent,
    UnsavedChangesComponent,
    TosDialogComponent,
    ConfirmationDialogComponent,
  ],
  exports: [
    HeaderComponent,
    SideNavComponent,
    LayoutComponent,
    BtpProgressDirective,
    MiniSpinnerComponent,
    DropdownComponent,
    ToastComponent,
    BannerComponent,
    HyperlinkCardComponent,
    SubHeaderComponent,
    LoadingSpinnerComponent,
    ProgressCircleComponent,
    BasicPageComponent,
    TranslationComponent,
    SummaryCardComponent,
    SummaryCardListComponent,
    UnsavedChangesComponent,
  ],
})
export class CoreModule {}
