import { Pipe, PipeTransform } from '@angular/core';
import { TalentPlatformSettingsService } from '../services/talent-platform-settings.service';

@Pipe({
  name: 'tpConfig',
})
export class TalentPlatformConfigPipe implements PipeTransform {
  constructor(private tpService: TalentPlatformSettingsService) {}

  transform(value: string): any {
    const x = value?.startsWith('Enable') ? this.tpService.getConfigFlag(value) : this.tpService.getConfigString(value);
    return x;
  }
}
