<h1 mat-dialog-title>
  <profile-translation key="talent_portal_unsaved_changes_title"></profile-translation>
</h1>
<div mat-dialog-content>
  <p>
    <profile-translation key="talent_portal_unsaved_changes_content"></profile-translation>
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button class="btp-btn-tertiary" (click)="onClick(false)">
    <profile-translation key="cancel"></profile-translation>
  </button>
  <button mat-button (click)="onClick(true)" cdkFocusInitial>
    <profile-translation key="yes"></profile-translation>
  </button>
</div>
