import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppUrls } from '../config/app-urls';
import { LayoutComponent } from './core/components/layout/layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { CompletedTasksComponent } from './features/tasks/completed-tasks-details/completed-tasks-details.component';
import { CompletedTaskDetailsResolver, CompletedTasksResolver, OpenTasksResolver } from './features/tasks/resolvers/tasks.resolver';
import { TasksComponent } from './features/tasks/tasks.component';
import { ApplicantResolver } from './shared/resolvers/applicant.resolver';
import { CustomerBrandingResolver, CustomerI18nResolver } from './shared/resolvers/customer.resolver';

const appRoutes: Routes = [
  {
    path: AppUrls.relPathToAppModule,
    component: LayoutComponent,
    canActivate: [AuthGuard],
    resolve: { i18n: CustomerI18nResolver, branding: CustomerBrandingResolver },
    runGuardsAndResolvers: "always",
    children: [
      {
        path: AppUrls.relPathToHome,
        loadChildren: () =>
          import('./features/home/home.module').then((m) => {
            console.debug('lazy loading homeModule');
            return m.HomeModule;
          }),
      },
      {
        path: AppUrls.relPathToJobs,
        loadChildren: () =>
          import('./features/jobs/jobs.module').then((m) => {
            console.debug('lazy loading jobsModule');
            return m.JobsModule;
          }),
      },
      // {
      //   path: 'tasks',
      //   loadChildren: () => import('./features/tasks/tasks.module').then(m => {
      //     console.debug('lazy loading tasksModule');
      //     return m.TasksModule;
      //   })
      // },
      {
        path: AppUrls.relPathToTasks,
        component: TasksComponent,
        resolve: {
          openTasks: OpenTasksResolver,
          completedTasks: CompletedTasksResolver,
          applicant: ApplicantResolver,
        },
        children: [
          {
            path: 'open/:sigRequestId',
            outlet: 'completedTasksOutlet',
            resolve: { completedTaskDetails: CompletedTaskDetailsResolver },
            component: CompletedTasksComponent,
          },
        ],
      },
      {
        path: AppUrls.relPathToTimeAndExpense,
        loadChildren: () =>
          import('./features/time-and-expense/time-and-expense.module').then((m) => {
            console.debug('lazy loading timeAndExpenseModule');
            return m.TimeAndExpenseModule;
          }),
      },
      {
        path: AppUrls.relPathToProfile,
        loadChildren: () =>
          import('./features/profile/profile.module').then((m) => {
            console.debug('lazy loading profileModule');
            return m.ProfileModule;
          }),
      },
    ],
  },
  {
    path: AppUrls.relPathToLegacyModule,
    loadChildren: () =>
      import('./profile-js.module').then((m) => {
        console.debug('DEFAULT - lazy loading profileJsModule');
        return m.ProfileJsModule;
      }),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      // enableTracing: true // <-- debugging purposes only
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
