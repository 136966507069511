import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, Observable, Subject, takeUntil } from 'rxjs';
import { UxService } from 'src/profile-ng/app/core/services/ux.service';
import { BaseContainerComponent } from '../../core/abstract/containers/base.container.component';
import { ApplicationStatus } from '../../core/constants/application-status.enum';
import { BreakpointObserverService } from '../../core/services/breakpoint-observer.service';
import { LayoutService } from '../../core/services/layout.service';
import { PrescreeningService } from '../../core/services/prescreening.service';
import { TalentPlatformSettingsService } from '../../core/services/talent-platform-settings.service';
import { TranslationService } from '../../core/services/translation.service';
import { Applicant } from '../../shared/models/applicant.model';
import { CustomI18n } from '../../shared/models/i18n.model';
import { ProfileStepDefinition } from '../../shared/models/profile-step-definition.model';
import { Task, TaskCategory } from '../../shared/models/tasks.model';
import { ApplicantService } from '../../shared/services/applicant.service';
import { CompanyService } from '../../shared/services/company.service';
import { TasksService } from './tasks.service';

@Component({
  selector: 'profile-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksComponent implements OnInit, OnDestroy {
  isMobile$: Observable<boolean>;
  destroy = new Subject();
  openTasks: Task[];
  completedTasks: Task[];
  tasksDisplayed: Task[];
  profileCompletionStatus: string;
  showPrescreenStep: boolean;
  incompleteProfileStepsCount: number;
  profileSteps: ProfileStepDefinition[] = [];
  percent: number;
  deg: number;
  tasksInChronologicalOrder: boolean;
  completeProfileBeforeOnboarding: boolean;
  disableCompleteProfileFromTaskList: boolean;
  isCompleteProfileCardVisible: boolean;
  applicant: Applicant;
  ignoreTaskDisabledState: boolean;
  taskCategoryDisplayed: TaskCategory;
  i18n: CustomI18n;

  constructor(
    private breakpointService: BreakpointObserverService,
    private route: ActivatedRoute,
    private router: Router,
    private prescreeningService: PrescreeningService,
    private taskService: TasksService,
    private tpsService: TalentPlatformSettingsService,
    private detector: ChangeDetectorRef,
    private uxService: UxService,
    private companyService: CompanyService,
  ) {}

  ngOnInit() {
    this.i18n = this.companyService.i18n;
    this.isMobile$ = this.breakpointService.isMobile$.pipe(debounceTime(200), takeUntil(this.destroy));

    this.openTasks = this.route.snapshot.data['openTasks'].data;
    this.completedTasks = this.route.snapshot.data['completedTasks'].data;
    this.configureTaskList('open');
    this.profileCompletionStatus = this.route.snapshot.data['openTasks'].profileCompletionStatus;
    this.applicant = this.route.snapshot.data['applicant'];

    this.tasksInChronologicalOrder = this.tpsService.getConfigFlag('EnableEnforceTaskListOrder');
    this.completeProfileBeforeOnboarding = this.tpsService.getConfigFlag('CompleteProfileBeforeOnboarding');
    this.disableCompleteProfileFromTaskList = this.tpsService.getConfigFlag('DisableCompleteProfileTaskList');

    this.prescreeningService.getPreScreeningTemplateIds(this.applicant.categoryName).subscribe((templateIds) => {
      this.uxService.loading(false); // set in RESOLVER
      this.showPrescreenStep = templateIds.length > 0;
      this.profileSteps = this.getProfileSteps();
      this.incompleteProfileStepsCount = this.profileSteps.filter((x) => !x.complete).length;
      this.setProfileCompletionPercentage();
      this.showCompleteProfileCard(this.taskCategoryDisplayed);
      this.detector.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }

  configureTaskList(taskCategory: TaskCategory) {
    this.taskCategoryDisplayed = taskCategory;
    this.tasksDisplayed = this.getTasksByCategory(taskCategory);
    this.showCompleteProfileCard(taskCategory);
  }

  getTasksByCategory(taskCategory: TaskCategory): Task[] {
    return taskCategory === 'open' ? this.openTasks : this.completedTasks;
  }

  getProfileSteps() {
    return this.taskService
      .getProfileSteps(this.showPrescreenStep)
      .map((x) => {
        x.complete = this.isStepCompleted(this.profileCompletionStatus, x.status);
        x.isActive = x.active();
        return x;
      })
      .filter((x) => x.isActive);
  }

  isStepCompleted(currentStatus: string, step: ApplicationStatus): boolean {
    return ApplicationStatus[currentStatus] >= step;
  }

  setProfileCompletionPercentage() {
    // Comment from Able Profile app -> Sign up is equal to 10%, so we tack that on automatically to the
    // percentage complete for the remaining 90% of the application
    this.percent = Math.round(((this.profileSteps.filter((x) => x.complete).length / this.profileSteps.length) * 0.9 + 0.1) * 100);
    this.deg = Math.round(360 * (this.percent / 100));
  }

  navigateToProfileSection = () => {
    this.taskService.navigateToProfileSection(this.profileSteps, this.route.parent);
  };

  isTaskDisabled(index: number): boolean {
    if (this.ignoreTaskDisabledState) return false;
    return (
      (this.tasksInChronologicalOrder && index > 0) || (!this.disableCompleteProfileFromTaskList && this.completeProfileBeforeOnboarding && this.incompleteProfileStepsCount > 0)
    );
  }

  isTaskCompleted(task: Task): boolean {
    this.ignoreTaskDisabledState = this.completedTasks.findIndex((t) => t.name === task.name) >= 0;
    return this.ignoreTaskDisabledState;
  }

  showCompleteProfileCard(currentTab: string) {
    this.isCompleteProfileCardVisible = !this.disableCompleteProfileFromTaskList && (!currentTab || currentTab === 'open' ? !!this.incompleteProfileStepsCount : false);
  }

  openTask(task: Task) {
    this.taskService.launchOpenTask(task, this.route.parent, this.profileSteps, this.applicant);
  }

  openCompletedTaskDetails(task: Task) {
    this.router.navigate([{ outlets: { completedTasksOutlet: `open/${task.sigRequestId}` } }], { relativeTo: this.route });
  }

  closeDetailsDrawerIfOpen() {
    this.router.navigate(['../btp/tasks'], { relativeTo: this.route.parent });
  }
}
