import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStatusService {
  private incompleteProfileStepsCount = new BehaviorSubject<number>(0);
  incompleteProfileStepsCount$ = this.incompleteProfileStepsCount.asObservable();

  setIncompleteProfileStepsCount(count: number) {
    this.incompleteProfileStepsCount.next(count); // Updates all subscribers with the new count
  }
}