import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'profile-unsaved-changes',
  templateUrl: './unsaved-changes.component.html',
  styleUrls: ['./unsaved-changes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsavedChangesComponent {
  constructor(public dialogRef: MatDialogRef<UnsavedChangesComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onClick(leave: boolean): void {
    this.dialogRef.close(leave);
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this.onClick(false);
  }
}
