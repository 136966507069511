import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class LocalStorageService {

  getItem(key: string): any {
    return localStorage.getItem(key);
  }

  getJson<T>(key: string, onError?: (err: any) => void): T|null {
    const str = this.getItem(key);
    if (!str)
      return null;
    try {
      return JSON.parse(str) as T;
    } catch (e) {
      if (onError) {
        onError(e);
      }
    }
    return null;
  }

  setItem(key: string, value: any) {
    if (!value) {
      // log an error so we can get the stacktrace
      console.error(`ignoring empty value for key: ${key}`)
      return;
    }
    localStorage.setItem(key, value);
  }

  removeItem(key: string): any {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}
