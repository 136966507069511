import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { take } from 'rxjs/operators';
import { BreakpointObserverService } from '../../../core/services/breakpoint-observer.service';
import { Helper } from '../../../shared/classes/helper';
import { Task, TaskItem } from '../../../shared/models/tasks.model';
import { CompanyService } from '../../../shared/services/company.service';

@Component({
  selector: 'profile-completed-tasks-details',
  templateUrl: './completed-tasks-details.component.html',
  styleUrls: ['./completed-tasks-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompletedTasksComponent implements OnInit {
  @ViewChild('completedTaskTemplate', { read: TemplateRef }) completedTaskTemplate: TemplateRef<any>;
  completedTaskTemplateRef: MatBottomSheetRef;
  dateFormat = 'MM/DD/YYYY';

  completedTaskDetails$: Observable<Task>;
  totalDownload: number;

  isMobile$: Observable<boolean>;
  destroy = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private matBottomSheet: MatBottomSheet,
    private breakpointService: BreakpointObserverService,
    private companyService: CompanyService,
  ) {}

  ngOnInit(): void {
    this.completedTaskDetails$ = this.route.data.pipe(
      map((data) => {
        const task = data['completedTaskDetails'] as Task;
        this.totalDownload = task.items.filter((x) => x.downloadable == true).length;
        return task;
      }),
    );
    this.isMobile$ = this.breakpointService.isMobile$.pipe(
      debounceTime(200),
      tap((isMobile) => this.handleBottomDrawer(isMobile)),
      takeUntil(this.destroy),
    );

    this.dateFormat = this.companyService.i18n.dateFormat;
  }

  onDownloadClick(url: string) {
    window.open(url, '_blank');
  }

  downloadAll(items: TaskItem[]) {
    items.forEach(function (item) {
      if (item.downloadable) {
        window.open(item.file?.url);
      }
    });
  }

  handleBottomDrawer(isMobile: boolean) {
    if (isMobile) this.openBottomDrawer();
    else {
      const drawerAlreadyClosedOrNeverOpened = !this.completedTaskTemplateRef;
      if (!drawerAlreadyClosedOrNeverOpened) this.closeBottomDrawer();
    }
  }

  openBottomDrawer() {
    this.completedTaskTemplateRef = this.matBottomSheet?.open(this.completedTaskTemplate, {
      panelClass: ['btp-scroll'],
      disableClose: true,
    });
    this.completedTaskTemplateRef
      .keydownEvents()
      .pipe(takeUntil(this.destroy))
      .subscribe((x) => {
        if (x.key === 'Escape') this.onClose();
      });
    this.completedTaskTemplateRef
      .backdropClick()
      .pipe(takeUntil(this.destroy))
      .subscribe((_) => this.onClose());
  }

  closeBottomDrawer() {
    this.completedTaskTemplateRef
      ?.afterDismissed()
      .pipe(take(1))
      .subscribe((_) => (this.completedTaskTemplateRef = null));
    this.completedTaskTemplateRef?.dismiss();
  }

  onClose() {
    this.router.navigate([`../`], { relativeTo: this.route }).then((leave) => {
      console.debug({ leave });
      // 'leave' boolean from UnsavedChanges CanDeactivate Guard
      if (leave) this.closeBottomDrawer();
    });
  }
}
