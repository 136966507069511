import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';
import { environment } from 'src/profile-ng/environments/environment';
import { Cacheable } from 'ts-cacheable';

export interface CurrentTOSVersionBE {
  id: number;
  name: string;
  content?: string;
  contentTranslationKey: string;
  creationDate: string; // timestamp
  isCurrentVersion: boolean;
}

export interface TOSAcceptanceBE {
  applicantId: number;
  tosId: number;
  acceptanceDate: string; // timestamp
}

export interface TOSAcceptanceDetailedBE extends TOSAcceptanceBE {
  tosVersionName: string;
}

export interface AcceptCurrentTOSPayload {
  applicantId: string;
  tosId: number;
  acceptanceDate: string; // timestamp 'YYYY-MM-DD HH:mm:ss'
}

@Injectable({
  providedIn: 'root',
})
export class TosService {
  currentTOS$ = this.getCurrentTOSVersion();
  applicantTOSAcceptanceList$ = this.getApplicantTOSAcceptanceList();
  userHasAcceptedLatestTerms$: Observable<[boolean, CurrentTOSVersionBE, TOSAcceptanceDetailedBE[]]> = forkJoin([this.applicantTOSAcceptanceList$, this.currentTOS$]).pipe(
    map(([acceptanceList, currentTOSVersion]) => {
      const userHasAcceptedLatest = !!acceptanceList?.find((x) => x.tosId === currentTOSVersion.id);
      return [userHasAcceptedLatest, currentTOSVersion, acceptanceList];
    }),
  );

  constructor(private _httpClient: HttpClient) {}

  @Cacheable({ maxAge: 5 * 60 * 1000 })
  getCurrentTOSVersion() {
    return this._httpClient.get<CurrentTOSVersionBE>(`${environment.applicant_core_api_host}/api/v1/tos/current`);
  }

  @Cacheable({ maxAge: 5 * 60 * 1000 })
  getApplicantTOSAcceptanceList() {
    return this._httpClient.get<TOSAcceptanceDetailedBE[]>(`${environment.applicant_core_api_host}/api/v1/tos/acceptance`);
  }

  acceptTOS(tosAcceptance: AcceptCurrentTOSPayload) {
    return this._httpClient.post<TOSAcceptanceBE>(`${environment.applicant_core_api_host}/api/v1/tos/acceptance`, tosAcceptance);
  }
}
