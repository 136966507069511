export enum CustomerConfig {
  AdditionalInformationMultiselectOptions = 'additional_info_multiselect_options',
  AllowSection1ChooseNoAuthRep = 'allow_section1_choose_no_auth_rep',
  AllowSection2Email = 'allow_section2_email',
  AllowSection2SMS = 'allow_section2_sms',
  CompleteProfileBeforeOnboarding = 'complete_profile_before_onboarding',
  CoreApiApplicantExport = 'enable_core_api_applicant_export',
  DetectDOBMismatchSection1 = 'detect_dob_mismatch_section_1',
  DetectSSNMismatchSection1 = 'detect_ssn_mismatch_section_1',
  DisableCompleteProfileTaskList = 'disable_complete_profile_tsk_list',
  DisableCoreAPIApplicantGetForm = 'disable_coreapi_apl_get_form',
  DisableInternationalPhoneNumbers = 'disable_international_phone_numbers',
  DisableRecruiterGetInTouch = 'disable_recruiter_get_in_touch',
  DisableJobPreferences = 'disable_job_preferences',
  DisableMobilePhoneSignup = 'disable_mobile_phone_signup',
  DisableProcessingStatus = 'disable_processing_status',
  DisableProfileSkillsChecklists = 'disable_profile_skills_checklists',
  DisableRadioGroupDefault = 'disable_radio_group_default',
  DisplayTasksOnChronologicalOrder = 'display_tasks_on_chronological_order',
  EnableAbleVerify = 'enable_able_verify',
  EnableAdditionalInformationMultiselect = 'enable_additional_info_multiselect',
  EnableAlternativeDocumentExamination = 'enable_alt_doc_examination',
  EnableApplicantAdvancedSearch = 'enable_applicant_search',
  EnableApplicantInstructions = 'enable_applicant_instructions',
  EnableCategoryMultiSelect = 'enable_category_multi_select',
  EnableCompanyTypeaheadUpgrade = 'enable_company_typeahead_upgrade',
  EnableCompletedTasksView = 'enable_completed_tasks_view',
  EnableCoreEdocViewing = 'enable_core_edoc_viewing',
  EnableEnforceTaskListOrder = 'enable_enforce_task_list_order',
  EnableEmployerRequestedInfoAfterI9 = 'enable_employer_requested_info_after_i9',
  EnableLocationBasedEverifyCaseView = 'enable_location_based_everify_case_view',
  EnablePresceeningTaxExemptions = 'enable_presceening_tax_exemptions',
  EnableSection2CommonDocs = 'enable_section2_common_docs',
  EnableSection2VideoModal = 'enable_section2_video_modal',
  EnableSection2VideoURL = 'enable_section2_video_url',
  EnableSelfServiceTaskAssignment = 'enable_self_service_tasks',
  EnableSkillChecklists = 'enable_skill_checklists',
  EnableTaxCreditOnApplication = 'enable_tax_credit_on_application',
  HideEduFromApp = 'hide_edu_from_app',
  HideWorkFromApp = 'hide_work_from_app',
  MaximumCandidateInactivityTime = 'maximum_candidate_inactivity_time',
  RequireSection1SSN = 'require_section1_ssn',
  RestrictCompletedEdocDownloading = 'restrict_completed_edoc_downloading',
  S3PresignedGetUrlTimeToLiveMinutes = 's3_presigned_get_url_time_to_live_minutes',
  EnableCountrySelection = 'enable_country_selection',
  EnableTalentPlatform = 'enable_talent_platform',
  EnableJobSearch = 'enable_job_search',
  EnableJobSearchCriteriaCategory = 'enable_job_search_criteria_category',
  EnableJobSearchCriteriaSkill = 'enable_job_search_criteria_skill',
  EnableJobSearchCriteriaSpecialty = 'enable_job_search_criteria_specialty',
  EnableJobSearchCriteriaIndustry = 'enable_job_search_criteria_industry',
  EnableJobSearchCriteriaEmploymentType = 'enable_job_search_criteria_emp_type',
  EnableJobDisplayListStartDate = 'enable_job_display_list_start_date',
  EnableJobDisplayListEndDate = 'enable_job_display_list_end_date',
  EnableJobDisplayListJobCity = 'enable_job_display_list_job_city',
  EnableJobDisplayListPayRate = 'enable_job_display_list_pay_rate',
  EnableJobDisplayListSalary = 'enable_job_display_list_salary',
  EnableJobDisplayDetailStartDate = 'enable_job_display_detail_start_date',
  EnableJobDisplayDetailEndDate = 'enable_job_display_detail_end_date',
  EnableJobDisplayDetailJobCity = 'enable_job_display_detail_job_city',
  EnableJobDisplayDetailPayRate = 'enable_job_display_detail_pay_rate',
  EnableJobDisplayDetailSalary = 'enable_job_display_detail_salary',
  PhotoMatchDocumentsRequired = 'photo_match_documents_required',
  EnablePlacements = 'enable_placements',
  PlacementsDisplayListStatuses = 'placements_display_list_statuses',
  EnablePlacementsDisplayListStatus = 'enable_placements_display_list_status',
  EnablePlacementsDisplayListTitle = 'enable_placements_display_list_title',
  EnablePlacementsDisplayListCompany = 'enable_placements_display_list_company',
  EnablePlacementsDisplayListStartDate = 'enable_placements_display_list_st_date',
  EnablePlacementsDisplayListEndDate = 'enable_placements_display_list_end_date',
  EnablePlacementsDisplayListPayRate = 'enable_placements_display_list_pay_rate',
  EnablePlacementsDisplayDetailStatus = 'enable_placements_display_detail_status',
  EnablePlacementsDisplayDetailTitle = 'enable_placements_display_detail_title',
  EnablePlacementsDisplayDetailCompany = 'enable_placements_display_detail_co',
  EnablePlacementsDisplayDetailCompanyAddress = 'enable_placements_display_detail_co_add',
  EnablePlacementsDisplayDetailStartDate = 'enable_placements_display_detail_st_date',
  EnablePlacementsDisplayDetailEndDate = 'enable_placements_display_detail_en_date',
  EnablePlacementsDisplayDetailPayRate = 'enable_placements_display_detail_py_rate',
  EnableATSTimeAndExpense = 'enable_ATS_time_and_expense',
  EnableATSTimeAndExpenseIFrame = 'enable_ATS_time_and_expense_iframe',
  OpenFormsInLegacyCandidatePortal = 'open_forms_in_legacy_candidate_portal',
  SubmissionDisplayListStatuses = 'submission_display_list_statuses',
  TalentPlatformMaxTaskCount = 'talent_platform_max_task_count',
  EnableAutomation = 'enable_automation',
  EnableResumeUpload = 'enable_resume_upload',
  EnableSpecialtiesSelection = 'enable_specialties_selection',
  EnablePrimaryAndSecondarySpecialties = 'enable_primary_and_secondary_specialties',
  DisableShiftAvailability = 'hide_shifts_available',
  PromptForDaysAvailable = 'prompt_for_days_available',
  DisableMapNullMoneyField = 'disable_map_null_money_field',
  I9ValidationForEVerify = 'i9_validation_for_everify',
}
