import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import { BaseContainerComponent } from '../../abstract/containers/base.container.component';
import { LayoutService } from '../../services/layout.service';
import { TalentPlatformSettingsService } from '../../services/talent-platform-settings.service';
import { TranslationService } from '../../services/translation.service';
import { Toast, ToastService } from './../../services/toast.service';
import { UxService } from './../../services/ux.service';

@Component({
  selector: 'profile-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent extends BaseContainerComponent implements OnInit, OnDestroy {
  loading$: Observable<boolean>;
  toasts$: Observable<Toast[]>;

  constructor(
    protected layoutService: LayoutService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected translationService: TranslationService,
    protected tpsService: TalentPlatformSettingsService,
    public uxService: UxService,
    private toastService: ToastService,
  ) {
    super();
  }

  override ngOnInit(): void {
    this.uxService.changeBrandingTheme(this.activatedRoute.snapshot.data['branding']);
    super.ngOnInit();
    this.subscriptions.push(
      ...[
        this.urlFragment$.subscribe((fragment) => {
          if (fragment != null) {
            this.scrollToFragment(fragment, {
              behavior: 'smooth',
              focus: true,
            });
          }
        }),
      ],
    );
    this.loading$ = this.uxService.loading$;
    this.toasts$ = this.toastService.toasts$;
    document.getElementById('profile-style-css')?.remove();
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    this.uxService.setDocumentClickedTarget(event.target);
  }

  override async ngOnDestroy() {
    super.ngOnDestroy();
    globalCacheBusterNotifier.next();
    let headID = document.getElementsByTagName('head')[0];
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.id = 'profile-style-css';
    link.href = 'app/bundle/style.css';
    headID?.appendChild(link);
  }
}
