import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filteredArrayLength',
  pure: false,
})
export class FilteredArrayLengthPipe implements PipeTransform {
  transform(items: any[], key: string): boolean {
    if (!items || !key) {
      return false;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter((item) => item[key])?.length > 0;
  }
}
