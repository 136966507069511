import { InjectionToken } from '@angular/core';
import { TranslationService } from '../services/translation.service';

// export const defaultErrors = {
//   required: (error) => `This field is required`,
//   email: (error) => `Invalid email format`,
//   minlength: ({ requiredLength, actualLength }) => `Input should be at least ${requiredLength} characters`,
//   maxlength: ({ requiredLength, actualLength }) => `Input should be at most ${requiredLength} characters`,
//   pattern: (error) => `Invalid entry`,
//   minDate: ({ requiredMinDate }) => `Min date is ${requiredMinDate}`,
//   maxDate: ({ requiredMaxDate }) => `Max date is ${requiredMaxDate}`,
//   internationalPhoneNumber: () => `Invalid Phone Number`,
// };

export const translationErrors: { [key: string]: (err: any) => { translationKey: string; templateVars: any[] } } = {
  required: (error) => ({ translationKey: 'required_field', templateVars: [] }),
  email: (error) => ({ translationKey: 'invalid_email', templateVars: [] }),
  minlength: ({ requiredLength, actualLength }) => ({ translationKey: 'es_min', templateVars: [requiredLength] }),
  maxlength: ({ requiredLength, actualLength }) => ({ translationKey: 'es_max', templateVars: [requiredLength] }),
  minDate: ({ requiredMinDate }) => ({ translationKey: 'date_at_least', templateVars: [requiredMinDate] }),
  maxDate: ({ requiredMaxDate }) => ({ translationKey: 'date_at_most', templateVars: [requiredMaxDate] }),
  modulo: ({ moduloValue }) => ({ translationKey: 'modulo_validator', templateVars: [moduloValue] }),
  min: (error) => ({ translationKey: 'number_validator', templateVars: [] }),
  numberWithLeadingZero: () => ({ translationKey: 'number_with_leading_zeros', templateVars: [] }),
  confirmDynamicField: () => ({ translationKey: 'confirm_dynamic_field', templateVars: [] }),
  internationalPhoneNumber: () => ({ translationKey: 'invalid_phone_number', templateVars: [] }),
};

export const FORM_ERRORS = new InjectionToken('FormErrors');

export function errorsFactory() {
  return (translationService: TranslationService): {} => {
    return Object.entries(translationErrors).reduce((acc, [errKey, errFunc]) => {
      let newErrFunc = (err) => {
        let { translationKey, templateVars } = errFunc(err);
        if (templateVars?.length) return translationService.translateTemplate(translationKey, templateVars);
        return translationService.getTranslation(translationKey);
      };

      acc = { ...acc, [errKey]: newErrFunc };
      return acc;
    }, {});
  };
}
