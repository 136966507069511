import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Toast {
  type: 'Success' | 'Error' | 'Info';
  title: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private delay = 6000;
  private _toasts = new BehaviorSubject<Toast[]>(null);
  public toasts$: Observable<Toast[]> = this._toasts.asObservable();

  private add(toast: Toast) {
    const toasts = this._toasts.getValue() ?? [];
    this._toasts.next([toast, ...toasts]);
    if (toast.type !== 'Error') {
      setTimeout(() => {
        this._toasts.next(this._toasts.getValue()?.filter((v) => v !== toast));
      }, this.delay);
    }
  }

  success(title: string, message: string) {
    this.add({ type: 'Success', title, message });
  }

  error(title: string, message: string) {
    this.add({ type: 'Error', title, message });
  }

  info(title: string, message: string) {
    this.add({ type: 'Info', title, message });
  }

  remove(index: number) {
    this._toasts.next(this._toasts.getValue()?.filter((toast, i) => i !== index));
  }
}
