import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AngularJSResourceService {
  private _injector: any;
  private get $injector(): any {
    if (!this._injector) {
      this._injector = (window as any).angular?.element(document.body).injector();
    }
    return this._injector;
  }

  getResource(name: string): any | null {
    return this.$injector?.has(name) ? this.$injector.get(name) : null;
  }
}
