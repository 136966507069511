<div>
  <ng-container *ngIf="data && data.length > 0">
    <div *ngIf="!isMobile" class="table">
      <div class="table-header">
        <div class="th th-name">{{ nameColumnHeader }}</div>
        <div class="th th-name">{{ completedDateColumnHeader }}</div>
        <div class="th th-status" *ngIf="showStatus">{{ statusColumnHeader }}</div>
        <div class="th th-action">{{ actionColumnHeader }}</div>
      </div>
      <ng-container *ngIf="data && data.length > 0">
        <ng-container *ngFor="let row of data">
          <div class="task-card">
            <ng-container>
              <div class="task-card-item">
                <div class="card-row card-name">
                  <span>{{ row.name }}</span>
                </div>
                <div class="card-row card-name">
                  <span>{{ getCompletedDateFormated(row.completed_date) }}</span>
                </div>
                <div class="card-profile-status" *ngIf="row.profile_status"><br />{{ row.profile_status }}</div>
                <div class="card-row card-status" *ngIf="showStatus"><profile-task-status [status]="row.status" [rejectedReason]="rejectedReason"></profile-task-status></div>
                <div class="card-row card-action"><profile-task-action [item]="row"></profile-task-action></div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div *ngIf="isMobile">
      <ng-container *ngFor="let row of data">
        <div class="mobile-card">
          <div class="task-card-item">
            <div class="card-header">
              <div class="card-row card-name">
                <span>{{ row.name }}</span>
              </div>
              <div *ngIf="showStatus"><profile-task-status [status]="row.status" [rejectedReason]="rejectedReason"></profile-task-status></div>
            </div>

            <div class="mobile-profile-status" *ngIf="row.profile_status">{{ row.profile_status }}</div>
            <div class="card-row card-action"><profile-task-action [item]="row"></profile-task-action></div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div *ngIf="data === null">
    <h6>Loading Completed Tasks...</h6>
  </div>

  <div *ngIf="data && data.length === 0">
    <div class="no-tasks">
      <h6>No Completed Tasks</h6>
    </div>
  </div>
</div>
