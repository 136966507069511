import { HashLocationStrategy, Location, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { UpgradeModule } from '@angular/upgrade/static';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { httpInterceptorProviders } from './core/interceptors/httpInterceptorProviders';
import { TranslationService } from './core/services/translation.service';
import { errorsFactory, FORM_ERRORS } from './core/validation/form-control-errors';
import { CustomUrlSerializer } from './CustomUrlSerializer';
import { TaskListComponent } from './features/tasks/task-list/task-list.component';
import { TaskNameComponent } from './features/tasks/task-name/task-name.component';
import { DialogsModule } from './shared/modules/dialogs';
import { CompanyService } from './shared/services/company.service';
import { DescriptionComponent } from './shared/modules/typography/description/description.component';
import { TitleComponent } from './shared/modules/typography/title/title.component';
import { TypographyModule } from './shared/modules/typography/typography.module';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, UpgradeModule, CoreModule, MatNativeDateModule, HttpClientModule, TypographyModule, AppRoutingModule, DialogsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [CompanyService],
      useFactory: (companyService: CompanyService) => () => companyService.getCustomerId(),
    },
    Location,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: FORM_ERRORS, useFactory: errorsFactory(), deps: [TranslationService] },
    httpInterceptorProviders,
  ],
})
export class AppModule {
  constructor(private injector: Injector) {
    const AbleTitleElm = createCustomElement(TitleComponent, { injector: this.injector });
    customElements.define('able-title-wc', AbleTitleElm);

    const AbleDescriptionElm = createCustomElement(DescriptionComponent, { injector: this.injector });
    customElements.define('able-description-wc', AbleDescriptionElm);

    const ProfileTaskListElm = createCustomElement(TaskListComponent, { injector: this.injector });
    customElements.define('profile-task-list', ProfileTaskListElm);

    const AbleTaskNameElm = createCustomElement(TaskNameComponent, { injector: this.injector });
    customElements.define('able-task-name-wc', AbleTaskNameElm);

    const AbleTaskListElm = createCustomElement(TaskListComponent, { injector: this.injector });
    customElements.define('able-task-list-wc', AbleTaskListElm);
  }
}
