import { Injectable } from '@angular/core';
import { PCacheable } from 'ts-cacheable';
import { AngularJSResourceService } from './angular-js-resource.service';
import { profile_i18l } from '../models/profile-i18l.model';
import {LocalStorageService} from "../../localStorage.service";

export interface SessionNotification {
  title: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private get sessionService(): any {
    return this.ngService?.getResource('SessionService');
  }

  constructor(private ngService: AngularJSResourceService, private localStorage: LocalStorageService) {}

  public get profileAccessToken(): string {
    return this.sessionService?.getAccessToken() || this.localStorage.getItem('profile-accessToken');
  }

  public get profileApplicantId(): string {
    return this.sessionService?.getApplicantId() || this.localStorage.getItem('profile-applicantId');
  }

  public get applicantPrelimInfoFilledOut(): boolean {
    return this.sessionService?.getApplicantPrelimInfoFilledOut() || this.localStorage.getItem('profile-prelimInfoFilledOut');
  }

  public get profileWfmUserId(): string {
    return this.localStorage.getItem('profile-wfm-userId');
  }

  public get userApplicantLogin(): string {
    return this.localStorage.getItem('user-applicant-login');
  }

  public get profileCustomerId(): string {
    return this.localStorage.getItem('profile-customerId');
  }

  public get getProfile_i18l(): profile_i18l {
    const profile_i18l = this.localStorage.getItem('profile-i18n');
    if (!profile_i18l) {
      this.sessionService?.getCustomerLanguageContentWithConfigs(this.profileCustomerId);
      return <profile_i18l>{}
    }
    try {
      return JSON.parse(profile_i18l);
    } catch {
      return <profile_i18l>{};
    }
  }

  public returnToTaskListOnAllCredentialsUploaded(returnToTaskList: any) {
    this.sessionService?.setReturnToTaskListOnAllCredentialsUploaded(returnToTaskList);
  }

  public returnWhere(returnWhere: any) {
    this.sessionService?.setReturnWhere(returnWhere);
  }

  @PCacheable({ maxAge: 1000 * 60 * 60 }) // caching Promise response
  public refreshSession(): Promise<any> {
    return this.sessionService?.refreshSession();
  }

  public getNotification(): SessionNotification|null {
    return this.localStorage.getJson<SessionNotification>("profile-notification", () => this.clearNotification())
  }

  public clearNotification(): void {
    this.localStorage.removeItem("profile-notification");
  }
}
