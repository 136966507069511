<div *ngIf="uxService.navigationVisible">
  <div *ngIf="(isMobile$ | async) !== true; else mobileLayout" id="btp-sidebar">
    <div class="btp-sidenav btp-shadow-high profile">
      <ul class="btp-sidenav-ul">
        <ng-container *ngFor="let item of navBarItems">
          <li *ngIf="(item.hide | async) !== true && item.link" routerLinkActive="active" [routerLink]="item.link" class="btp-sidenav-item" (click)="clearJobSeach()">
            <i class="btp-sidenav-icon" [ngClass]="item.icon"></i>
            <div class="btp-sidenav-link">
              <profile-translation [key]="item.translationKey" [defaultValue]="item.defaultLabel"></profile-translation>
            </div>
          </li>
          <li *ngIf="(item.hide | async) !== true && item.routeAction" routerLinkActive="active" class="btp-sidenav-item" (click)="clearJobSeach()">
            <a (click)="item.routeAction(this)" class="btp-sidenav-item">
              <i class="btp-sidenav-icon" [ngClass]="item.icon"></i>
              <div class="btp-sidenav-link">
                <profile-translation [key]="item.translationKey" [defaultValue]="item.defaultLabel"></profile-translation>
              </div>
            </a>
          </li>
        </ng-container>
        <ng-container *ngFor="let item of moreDrawerItems">
          <li *ngIf="(item.hide | async) !== true && item.link" routerLinkActive="active" [routerLink]="item.link" class="btp-sidenav-item" (click)="clearJobSeach()">
            <i class="btp-sidenav-icon" [ngClass]="item.icon"></i>
            <div class="btp-sidenav-link">
              <profile-translation [key]="item.translationKey" [defaultValue]="item.defaultLabel"></profile-translation>
            </div>
          </li>
        </ng-container>

        <!-- ------------------------------- LOGOUT -------------------------------- -->

        <li class="btp-sidenav-logout" (click)="handleLogout()">
          <hr class="hr-bottom" />
          <i class="ri-logout-box-r-line btp-sidenav-icon"></i>
          <a class="btp-sidenav-link logout"> <profile-translation key="log_out"></profile-translation> </a>
        </li>
      </ul>
    </div>
  </div>

  <!-- ----------------------------------------------------------------------- -->
  <!--                             MOBILE TEMPLATE                             -->
  <!-- ----------------------------------------------------------------------- -->

  <ng-template #mobileLayout>
    <div class="btp-bottom-navbar" id="navbar-main-footer">
      <ng-container *ngFor="let item of navBarItems">
        <!-- ------------------------------ NAV LINK ------------------------------- -->
        <div *ngIf="(item.hide | async) !== true && item.link" class="btp-bottom-nav-col">
          <a class="btp-bottom-nav-link" [routerLink]="item.link" routerLinkActive="active" (click)="clearJobSeach()">
            <i class="btp-bottom-nav-icon" [ngClass]="item.icon"></i>
          </a>
        </div>
        <!-- ---------------------- NAV LINK W/ ROUTE ACTION ----------------------- -->
        <div *ngIf="(item.hide | async) !== true && item.routeAction" class="btp-bottom-nav-col">
          <a class="btp-bottom-nav-link" (click)="item.routeAction(this)">
            <i class="btp-bottom-nav-icon" [ngClass]="item.icon"></i>
          </a>
        </div>
      </ng-container>
      <div class="btp-bottom-nav-col">
        <a class="btp-bottom-nav-link" (click)="toggleMoreDrawer()">
          <i class="ri-more-line btp-bottom-nav-icon"></i>
        </a>
      </div>
    </div>

    <div class="bdOverlay" [ngClass]="{ 'bdOverlay--visible': isDrawerVisible }"></div>

    <div class="bdContainer btp-shadow-high" [ngClass]="{ 'bdContainer--visible': isDrawerVisible }">
      <div class="bdContainer-scroll">
        <div class="drawer-close">
          <i class="ri-close-line close-icon" (click)="toggleMoreDrawer()"></i>
        </div>

        <ul class="btp-drawer-list">
          <ng-container *ngFor="let item of moreDrawerItems">
            <li *ngIf="(item.hide | async) !== true && item.link" class="btp-drawer-item">
              <a class="btp-drawer-link" [routerLink]="item.link" routerLinkActive="active" target="{{ item.target }}" (click)="clearJobSeach()">
                <i class="btp-drawer-icon" [ngClass]="item.icon"></i>
                <profile-translation [key]="item.translationKey" [defaultValue]="item.defaultLabel"></profile-translation>
              </a>
            </li>
          </ng-container>
        </ul>

        <!-- ------------------------------- LOGOUT -------------------------------- -->

        <div class="logout-item">
          <a class="btp-drawer-logout" (click)="handleLogout()">
            <i class="ri-logout-box-r-line btp-drawer-icon-logout"></i>
            Logout
          </a>
        </div>
      </div>
    </div>
  </ng-template>
</div>
