import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, shareReplay, Subject, take, tap } from 'rxjs';
import tinycolor from 'tinycolor2';
import { UnsavedChangesComponent } from '../components/unsaved-changes/unsaved-changes.component';

@Injectable({
  providedIn: 'root',
})
export class UxService {
  private documentClickedTarget: Subject<HTMLElement> = new Subject<HTMLElement>();
  public documentClickedTarget$ = this.documentClickedTarget.asObservable().pipe(shareReplay());

  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this._loading.asObservable();

  private _componentLevelLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public componentLevelLoading$: Observable<boolean> = this._componentLevelLoading.asObservable();

  public navigationVisible: boolean = true;

  private _unsavedChanges: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public unsavedChanges$: Observable<boolean> = this._unsavedChanges.asObservable();
  private unsavedChangesDialogRef: MatDialogRef<UnsavedChangesComponent>;

  constructor(private dialog: MatDialog) {}

  openUnsavedChangesDialog(options: { title?: string; message?: string; cancelText?: string; confirmText?: string }) {
    // options currently unused
    this.unsavedChangesDialogRef = this.dialog.open(UnsavedChangesComponent, {
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
      },
    });
  }
  unsavedChangesDialogResponse(): Observable<boolean> {
    return this.unsavedChangesDialogRef.afterClosed().pipe(
      take(1),
      tap((leave) => {
        if (leave) this.unsavedChanges(false);
      }),
    );
  }

  hideAllNav() {
    this.navigationVisible = false;
  }

  showAllNav() {
    this.navigationVisible = true;
  }

  loading(bool: boolean = true) {
    this._loading.next(bool);
  }

  componentLevelLoading(bool: boolean = true) {
    this._componentLevelLoading.next(bool);
  }

  setDocumentClickedTarget(target: HTMLElement) {
    this.documentClickedTarget.next(target);
  }

  unsavedChanges(bool: boolean = true) {
    this._unsavedChanges.next(bool);
  }

  changeBrandingTheme(colors: string[]) {
    const primary = colors[0] || '#000';
    const accent = colors[1] || '#000';
    // const accent = colors[2];
    // Primary
    document.documentElement.style.setProperty('--primary-color', primary);
    let darker = tinycolor(primary).saturate(-8).lighten(-16).toHexString();
    document.documentElement.style.setProperty('--primary-color-darker', darker);
    let lighter = tinycolor(primary).lighten(15).toHexString();
    document.documentElement.style.setProperty('--primary-color-lighter', lighter);
    // Accent (AKA Secondary in WFM)
    document.documentElement.style.setProperty('--accent-color', accent);
    darker = tinycolor(accent).saturate(-8).lighten(-16).toHexString();
    document.documentElement.style.setProperty('--accent-color-darker', darker);
    lighter = tinycolor(accent).lighten(15).toHexString();
    document.documentElement.style.setProperty('--accent-color-lighter', lighter);
    // document.documentElement.style.setProperty('--accent-color', colors[2]);
  }
}
