export enum ApplicationStatus {
  SIGNUP = 0,
  BASICINFO = 1,
  TAXEXEMPT = 2,
  JOBPREFS = 3,
  EDUHIST = 4,
  WORKHIST = 5,
  SKILLCHECKLIST = 6,
  TAXCREDIT = 7,
  PRESCREEN = 8,
  COMPLETE = 9,
}
