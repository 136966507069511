<div class="task-status">
  <span class="status" [ngClass]="[status && status.title ? status.title : '']"> {{ status && status.title }} </span>
  <span *ngIf="status && status.message" class="fa fa-question-circle" (click)="onMessageClick($event)" profileBtpClickOutside (clickOutside)="hideMessage()"></span>
  <div class="rejectMessage" *ngIf="showStatusMessage">
    <div class="rejectTitle">
      <span> {{ rejectedReason }}</span>
    </div>
    <span> {{ status && status.message }}</span>
  </div>
</div>
