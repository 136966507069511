<div id="btp-toast-1" class="toast-container">
  <mat-card class="btp-shadow-high toast-wrapper">
    <!-- -------------------------------- ICON --------------------------------- -->
    <div class="toast-icon">
      <ng-container [ngSwitch]="data?.type">
        <i class="ri-checkbox-circle-fill toast-success" *ngSwitchCase="'Success'"></i>
        <i class="ri-spam-2-fill toast-error" *ngSwitchCase="'Error'"></i>
        <i class="ri-information-fill toast-info" *ngSwitchCase="'Info'"></i>
      </ng-container>
    </div>
    <!-- ------------------------------- CONTENT ------------------------------- -->
    <div class="toast-content">
      <b>{{ data?.title }}</b>
      <br />
      <span>{{ data?.message }}</span>
    </div>
    <!-- ---------------------------- CLOSE BUTTON ----------------------------- -->
    <div class="toast-close">
      <button mat-button class="btp-btn-circ btp-glow" (click)="onClose()">
        <i class="ri-close-line"></i>
      </button>
    </div>
  </mat-card>
</div>

<!-- Success Icon = Remix Icon: chackbox-circle-fill, Green #34C759)
Error Icon = Remix Icon: spam-2-fill,
Negative #FF3B30)Toast Message Transition = Transition from the right side -->
