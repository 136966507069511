import { Injectable } from '@angular/core';
import { CustomerConfig } from 'src/profile-ng/app/core/constants/customer-config.enum';
import { ConfigService } from 'src/profile-ng/app/core/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class TalentPlatformSettingsService {
  constructor(private configService: ConfigService) {}

  public getConfigFlag = (key: string): boolean => {
    let res = this.configService.getConfigBooleanOrDefault(CustomerConfig[key] || key) || false;
    return res;
  };

  public getConfigString = (key: string): string => {
    let res = this.configService.getConfigValue(CustomerConfig[key] || key) || null;
    return res;
  };
}
