import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, map, finalize } from 'rxjs';
import { UxService } from 'src/profile-ng/app/core/services/ux.service';
import { Task, TasksResponse } from 'src/profile-ng/app/shared/models/tasks.model';
import { ApplicantService } from 'src/profile-ng/app/shared/services/applicant.service';

@Injectable({
  providedIn: 'root',
})
export class OpenTasksResolver implements Resolve<TasksResponse> {
  constructor(private applicantService: ApplicantService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<TasksResponse> {
    return this.applicantService.getApplicantTasks();
  }
}

@Injectable({
  providedIn: 'root',
})
export class CompletedTasksResolver implements Resolve<TasksResponse> {
  constructor(private applicantService: ApplicantService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<TasksResponse> {
    return this.applicantService.getApplicantCompletedTasks();
  }
}

@Injectable({
  providedIn: 'root',
})
export class CompletedTaskDetailsResolver implements Resolve<Task> {
  constructor(private applicantService: ApplicantService, private uxService: UxService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Task> {
    return this.applicantService.getApplicantCompletedTasks().pipe(
      map((response: TasksResponse) => response.data.find((task) => task.sigRequestId.toString() === route.paramMap.get('sigRequestId'))),
      finalize(() => this.uxService.loading(false)),
    );
  }
}
