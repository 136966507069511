<div class="dialog" id="tos-dialog">
  <h1 mat-dialog-title>
    <profile-translation key="terms_of_use_title"></profile-translation>
  </h1>
  <div mat-dialog-content class="btp-scroll">
    <div class="confirm-heading">
      <profile-translation key="terms_of_use_subtitle"></profile-translation>
    </div>
    <br /><br />
    <div class="confirm-text" [innerHtml]="data.content"></div>
  </div>
  <div mat-dialog-actions>
    <button mat-button class="btp-btn-tertiary" (click)="onClick('yes')" (keydown)="$event.preventDefault()" cdkFocusInitial>
      <profile-translation key="agree" defaultValue="Agree"></profile-translation>
    </button>
  </div>
</div>
