import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'profile-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubHeaderComponent {
  @Input() actionText: string = '';
  @Input() actionTranslation: string = '';
  @Input() optionalRoute: string = '';

  constructor(private location: Location, private router: Router, private route: ActivatedRoute) {}

  public onClick(): void {
    if (!this.optionalRoute) {
      this.location.back();
    } else {
      this.router.navigate([this.optionalRoute], { relativeTo: this.route });
    }
  }
}
