import { Component, Input } from '@angular/core';

@Component({
  selector: 'able-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent {
  @Input() content: string = '';
}
