import { Component, Input } from '@angular/core';

@Component({
  selector: 'able-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent {
  @Input() content: string = '';
}
