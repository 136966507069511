<div class="dialog-container">
  <h2 mat-dialog-title *ngIf="!!data.title">{{ data.title }}</h2>
  <mat-dialog-content>
    <ng-container *ngIf="data.body">
      <p *ngFor="let paragraph of data.body">{{ paragraph }}</p>
    </ng-container>
    <ng-container *ngFor="let list of data.lists">
      <ul *ngIf="list.listItems.length > 0">
        <h4>{{ list.title }}</h4>
        <li *ngFor="let listItem of list.listItems" class="mat-body-1">
          {{ listItem }}
        </li>
      </ul>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <ng-container *ngFor="let action of data.actions">
      <button mat-button *ngIf="action.button.type === 'mat-button'" [color]="action.button.theme ?? 'primary'" (click)="triggerAction(action)">{{ action.button.text }}</button>
      <button mat-flat-button *ngIf="action.button.type === 'mat-flat-button'" [color]="action.button.theme ?? 'primary'" (click)="triggerAction(action)">
        {{ action.button.text }}
      </button>
      <button mat-raised-button *ngIf="action.button.type === 'mat-raised-button'" [color]="action.button.theme ?? 'primary'" (click)="triggerAction(action)">
        {{ action.button.text }}
      </button>
    </ng-container>
  </mat-dialog-actions>
</div>
