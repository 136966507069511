import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'profile-translation',
  template: `
  <span *ngIf="capitalize"> {{ translation$ | async | sentencecase }} </span>
  <span *ngIf="!capitalize"> {{ translation$ | async }} </span>
  `,
  styles: [':host {white-space: normal}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationComponent implements OnInit {
  @Input() key: string | Observable<string>;
  @Input() values: string[] | number[] | Observable<string[] | number[]>;
  @Input() defaultValue: string;
  @Input() translateValues: boolean;
  @Input() capitalize: boolean = true;

  public translation$: Observable<string>;

  constructor(private translationService: TranslationService) {}

  ngOnInit(): void {
    this.translation$ = this.translationService.getAsyncTranslation(this.key, this.values, this.defaultValue, this.translateValues);
  }
}
