import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Task, TaskCategory, TaskTypeTranslationKey } from 'src/profile-ng/app/shared/models/tasks.model';

@Component({
  selector: 'profile-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCardComponent {
  @Input() task: Task;
  @Input() taskCategory: TaskCategory = 'open';
  @Input() dateFormat: string;
  @Input() numberOfIncompleteProfileSteps: number;
  @Input() enforceTaskListOrderEnabled: boolean;
  @Input() completeProfileBeforeOnboarding: boolean;
  @Input() taskIsFirstInTaskList: boolean;

  @Output() openTask = new EventEmitter<Task>();
  @Output() openPackageInfo = new EventEmitter<Task>();

  get taskIsComplete(): boolean {
    return this.taskCategory === 'completed';
  }

  get taskIsPackage(): boolean {
    const { taskType } = this.task;
    return taskType === 'Package' || taskType === 'Custom Package';
  }

  get taskIsDisabled(): boolean {
    const profileMustBeCompletedFirst = this.completeProfileBeforeOnboarding && this.numberOfIncompleteProfileSteps > 0;
    const taskMustBeNextUpAndIsNot = this.enforceTaskListOrderEnabled && !this.taskIsFirstInTaskList;
    return profileMustBeCompletedFirst || taskMustBeNextUpAndIsNot;
  }

  get taskIsClickable(): boolean {
    return !this.taskIsDisabled || this.taskIsComplete;
  }

  get taskContainsDownload(): boolean {
    return this.task.items.some((x) => x.downloadable == true);
  }

  get taskHasADownloadableTaskItemWithUrl(): boolean {
    return this.task.items.some((taskItem) => taskItem.downloadable && taskItem.file?.url);
  }

  get showOpenTaskButton(): boolean {
    return !this.taskIsComplete && !this.taskIsDisabled;
  }

  get downloadAvailable(): boolean {
    return this.taskIsComplete && this.taskHasADownloadableTaskItemWithUrl;
  }

  get showPackageDetailsButton(): boolean {
    return this.taskIsComplete && this.taskIsPackage && !this.taskHasADownloadableTaskItemWithUrl;
  }

  get isPackageWithOneDownloadableTask(): boolean {
    return this.taskIsPackage && this.task.items.length === 1 && this.taskHasADownloadableTaskItemWithUrl;
  }

  get fileDownloadUrl(): string | undefined {
    return this.task.items[0].file?.url;
  }

  get secondaryFileDownloadUrl(): string | undefined {
    return this.task.items[0].secondaryFile?.url;
  }

  taskTypeTranslation = TaskTypeTranslationKey;

  taskClicked(task: Task): void {
    if (!this.taskIsComplete && !this.taskIsDisabled) {
      this.openTask.emit(task);
    }

    if (this.taskIsComplete) {
      if (this.downloadAvailable && (!this.taskIsPackage || this.isPackageWithOneDownloadableTask)) {
        window.open(this.fileDownloadUrl, '_blank');
      } else if (this.taskIsPackage) {
        this.openPackageInfo.emit(task);
      }
    }
  }

  secondaryFileDownload(): void {
    window.open(this.secondaryFileDownloadUrl, '_blank');
  }
}
