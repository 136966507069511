<div class="completed-tasks-container">
  <!-- ------------------------------- MOBILE -------------------------------- -->
  <ng-container *ngIf="isMobile$ | async; else completedTaskTemplate"></ng-container>

  <!-- ------------------------------- DESKTOP ------------------------------- -->
  <ng-template #completedTaskTemplate>
    <ng-container *ngIf="completedTaskDetails$ | async as completedTaskDetails">
      <mat-card class="completed-tasks-card btp-shadow-high btp-scroll">
        <div class="top-buttons-row">
          <div class="title-wrapper">
            <div class="widget-card-title">{{ completedTaskDetails.name }}</div>
          </div>
          <button id="drawer-close-btn" class="btp-btn-circ btp-glow close-btn" type="button" (click)="onClose()">
            <i class="ri-close-line"></i>
          </button>
        </div>

        <div class="subtitle-wrapper">
          <div class="widget-card-subtitle">
            <i class="icon ri-file-list-3-line"></i>
            {{ completedTaskDetails.taskType }}
          </div>
          <div class="widget-card-subtitle">
            <div class="widget-card-subtitle widget-card-subtitle--success">
              <i class="icon ri-checkbox-circle-fill"></i>
              <span class="info"><profile-translation key="completed" defaultValue="Completed"></profile-translation></span>
              <span class="date">
                {{ completedTaskDetails.completedDate | date : dateFormat }}
              </span>
            </div>
          </div>
        </div>

        <div class="task-cards-container btp-scroll">
          <div *ngFor="let item of completedTaskDetails.items" class="tasks-section">
            <mat-card class="task-card">
              <div class="task-buttons">
                <div class="task-name">
                  {{ item.name }}
                  <ng-container *ngIf="!!item.secondaryFile?.url">
                    (<profile-translation key="credential_image_capture_front" defaultValue="Front"></profile-translation>)
                  </ng-container>
                </div>
                <div>
                  <a *ngIf="item.downloadable" target="_blank">
                    <button mat-flat-button class="btp-btn-sm widget-action-btn" color="primary" (click)="onDownloadClick(item.file?.url)">
                      <i class="ri-download-line"></i>
                    </button>
                  </a>
                  <div *ngIf="!item.downloadable">
                    <profile-translation key="not_downloadable" defaultValue="Not Downloadable"></profile-translation>
                  </div>
                </div>
              </div>
            </mat-card>
            <mat-card class="task-card" *ngIf="!!item.secondaryFile?.url">
              <div class="task-buttons">
                <div class="task-name">
                  {{ item.name }}
                  (<profile-translation key="credential_image_capture_back" defaultValue="Back"></profile-translation>)
                </div>
                <div>
                  <a *ngIf="item.downloadable" target="_blank">
                    <button mat-flat-button class="btp-btn-sm widget-action-btn" color="primary" (click)="onDownloadClick(item.secondaryFile?.url)">
                      <i class="ri-download-line"></i>
                    </button>
                  </a>
                  <div *ngIf="!item.downloadable">
                    <profile-translation key="not_downloadable" defaultValue="Not Downloadable"></profile-translation>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
          <div class="final-buttons">
            <button id="drawer-cancel-btn" type="button" mat-button (click)="onClose()">
              <p>
                <profile-translation key="cancel"></profile-translation>
              </p>
            </button>
          </div>
        </div>
      </mat-card>
    </ng-container>
  </ng-template>
</div>
