import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, finalize, from, map, mergeMap, Observable, of, shareReplay, Subject, tap, throwError } from 'rxjs';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { environment } from '../../../environments/environment';
import { BtpProgressConst } from '../../core/constants/btp-progress.const';
import { BtpProgressStatus } from '../../core/models/btp-progress.model';
import { SessionService } from '../../core/services/session.service';
import { TalentPlatformSettingsService } from '../../core/services/talent-platform-settings.service';
import { ToastService } from '../../core/services/toast.service';
import { ApplicantProperty } from '../models/applicant-property.model';
import { Applicant, ApplicantDetails } from '../models/applicant.model';
import { ApplicationStatus } from '../models/applicationstatus';
import { JobCategory } from '../models/job.model';
import { Placement } from '../models/placement.model';
import { Task, TasksResponse } from '../models/tasks.model';
import { btpProgress } from '../utilities/rxjs.utility';
import { TranslationService } from './../../core/services/translation.service';
import { UxService } from './../../core/services/ux.service';

// import { mockApplicantTasks, mockApplicantTasksCompleted } from './../../features/jobs/mock/mockApplicant';
import { CompanyService } from './company.service';

const cacheBuster$ = new Subject<void>();

@Injectable({ providedIn: 'root' })
export class ApplicantService {
  private inChronologicalOrder: boolean;
  private profileAccessToken: string;
  private applicantId: string;
  private customerId: string;
  public applicantDetails: ApplicantDetails;

  private _currentPlacement: BehaviorSubject<Placement> = new BehaviorSubject<Placement>(null);
  public currentPlacement$: Observable<Placement> = this._currentPlacement.asObservable();

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private companyService: CompanyService,
    private tpService: TalentPlatformSettingsService,
    private translationService: TranslationService,
    private toastService: ToastService,
    private uxService: UxService,
  ) {
    this.profileAccessToken = this.sessionService.profileAccessToken;
    this.applicantId = this.sessionService.profileApplicantId;
    this.customerId = this.companyService.customerId;
    this.inChronologicalOrder = this.tpService.getConfigFlag('display_tasks_on_chronological_order');
  }

  // RESOLVER
  @Cacheable({ maxAge: 5 * 60 * 1000 })
  getApplicant(): Observable<Applicant> {
    const url = `${environment.applicant_core_api_host}/api/v1/applicants`;
    return this.http.get<Applicant>(url).pipe(shareReplay());
  }

  // RESOLVER
  @Cacheable({ maxAge: 1 * 60 * 1000 })
  getApplicantTasks(inChronologicalOrder = false): Observable<TasksResponse> {
    const params = { inChronologicalOrder: this.inChronologicalOrder, pageSize: 100 };
    const url = `${environment.applicant_core_api_host}/api/v1/tasks`;
    return this.http.get<TasksResponse>(url, { params }).pipe(
      mergeMap((res) => {
        // in case there's more than 100.
        if (res.outstandingTasks > res.data.length) {
          return this.http.get<TasksResponse>(url, { params: { ...params, pageSize: res.outstandingTasks } });
        } else {
          return of(res);
        }
      }),
      map((applicantTasks) => {
        const msPerDay = 1000 * 60 * 60 * 24; // milliseconds per day
        const todaysDate: any = new Date(); // today's date
        applicantTasks.data.map((task) => {
          if (task.expirationDate) {
            const expirationDate: any = new Date(task.expirationDate);
            task.expiresIn = Math.floor((expirationDate - todaysDate) / msPerDay);
          }
          return task;
        });
        return applicantTasks;
      }),
      shareReplay(),
    );
  }

  // RESOLVER
  @Cacheable({ maxAge: 1 * 60 * 1000 })
  getApplicantCompletedTasks(): Observable<TasksResponse> {
    const params = { inChronologicalOrder: this.inChronologicalOrder, pageSize: 100 };
    const url = `${environment.applicant_core_api_host}/api/v1/tasks/completed`;
    return this.http.get<TasksResponse>(url, { params }).pipe(
      mergeMap((res) => {
        // in case there's more than 100.
        if (res.outstandingTasks > res.data.length) {
          return from(this.http.get<TasksResponse>(url, { params: { ...params, pageSize: res.outstandingTasks } }));
        } else {
          return of(res);
        }
      }),
      shareReplay(),
    );
  }

  // RESOLVER
  @Cacheable({ maxAge: 5 * 60 * 1000, cacheBusterObserver: cacheBuster$ })
  getApplicantDetails(): Observable<ApplicantDetails> {
    const url = `${environment.php_host}/api/v2/customers/${this.customerId}/applicants/${this.applicantId}`;
    const params = { applicant_id: this.applicantId, is_applicant: 1 };
    return this.http.get<{ data; status }>(url, { params }).pipe(
      map((x) => x.data as ApplicantDetails),
      tap((applicantDetails) => (this.applicantDetails = applicantDetails)),
      shareReplay(),
    );
  }

  // RESOLVER
  @Cacheable({ maxAge: 5 * 60 * 1000, cacheBusterObserver: cacheBuster$ })
  getApplicantProperties(): Observable<ApplicantProperty[]> {
    const url = `${environment.applicant_core_api_host}/api/v1/properties?applicantId=${this.applicantId}`;
    return this.http.get<ApplicantProperty[]>(url).pipe(shareReplay());
  }

  @CacheBuster({ cacheBusterNotifier: cacheBuster$ })
  createOrUpdateProperties(data: ApplicantProperty[]): Observable<BtpProgressStatus> {
    const url = `${environment.applicant_core_api_host}/api/v1/properties/multi`;

    return this.http.patch<any>(url, data).pipe(
      btpProgress(BtpProgressConst.save, this.toastService),
      catchError((err) => {
        this.toastService.error(this.translationService.getTranslation('profile_save_error_title'), this.translationService.getTranslation('profile_save_error'));
        return of({ pending: false, status: 'Failed' });
      }),
    );
  }

  addOrUpdateApplicationStatus(customerId: string, status: ApplicationStatus, complete: 0 | 1): Observable<any> {
    const url = `${environment.php_host}/api/v2/customers/${customerId}/applicants/status?is_applicant=1`;

    const data = {
      applicant_id: this.applicantId,
      session_token: this.profileAccessToken,
      current_application_status: status,
      complete: complete,
    };

    return this.http.put<any>(url, data).pipe(
      catchError((err) => {
        return of({ pending: false, status: 'Failed' });
      }),
    );
  }

  updateApplicant(newApplicant: any, oldApplicant: ApplicantDetails): Observable<BtpProgressStatus> {
    oldApplicant.first_name = newApplicant.firstNameInput;
    oldApplicant.middle_name = newApplicant.noMiddleNameCheckbox ? '' : newApplicant.middleNameInput;
    oldApplicant.props.no_middle_name = newApplicant.noMiddleNameCheckbox ? '1' : '0';
    oldApplicant.last_name = newApplicant.lastNameInput;
    oldApplicant.alias = newApplicant.aliasInput;
    oldApplicant.email = newApplicant.emailInput;
    oldApplicant.mobile_phone = newApplicant.mobileInput?.internationalNumber ?? newApplicant.mobileInput;
    oldApplicant.home_phone = newApplicant.homeInput?.internationalNumber ?? newApplicant.homeInput;
    oldApplicant.contact_method = newApplicant.notificationsRadio;
    oldApplicant.country = newApplicant.addressCountrySelect;
    oldApplicant.address1 = newApplicant.address1Input;
    oldApplicant.address2 = newApplicant.address2Input;
    oldApplicant.city = newApplicant.addressCityInput;
    oldApplicant.state = newApplicant.addressStateSelect;
    oldApplicant.zip = newApplicant.addressZipInput;
    return this.updateApplicantShallow(oldApplicant);
  }

  updateApplicantShallow(data): Observable<BtpProgressStatus> {
    const url = `${environment.php_host}/api/v2/customers/${this.customerId}/applicants`;
    const params = { access_token: this.profileAccessToken, is_applicant: 1 };
    return this.http.put<Applicant>(url, data, { params }).pipe(btpProgress(BtpProgressConst.save, this.toastService));
  }

  @Cacheable({ maxAge: 5 * 60 * 1000 })
  getCurrentPlacement(): Observable<Placement> {
    const url = `${environment.applicant_core_api_host}/api/v1/Applicants/Placements/Current`;
    return this.http.get<Placement>(url).pipe(
      tap((placement) => {
        this._currentPlacement.next(placement);
      }),
    );
  }

  @Cacheable({ maxAge: 5 * 60 * 1000 })
  getAllPlacements(): Observable<Placement[]> {
    const url = `${environment.applicant_core_api_host}/api/v1/Applicants/Placements`;
    return this.http.get<Placement[]>(url);
  }

  getJobCategories(): Observable<JobCategory[]> {
    const url = `${environment.applicant_core_api_host}/api/v1/JobCategories`;
    return this.http.get<JobCategory[]>(url);
  }

  jobCategories$: Observable<JobCategory[]> = this.getJobCategories().pipe(
    map((listOfJobTypes) => {
      return listOfJobTypes.filter((jobType) => jobType.type === 'CATEGORY');
    }),
  );

  jobSpecialties$: Observable<JobCategory[]> = this.getJobCategories().pipe(
    map((listOfJobTypes) => {
      let specs = new Set();
      listOfJobTypes.forEach(job => {
        job.children.forEach(child => {
          if (child.type == 'SPECIALTY') specs.add(child);
        })
      })
      return Array.from(specs) as JobCategory[];
    }), shareReplay() 
  );

  getBteSsoUrl(): any {
    const url = `${environment.applicant_core_api_host}/api/v1/applicants/btesso`;
    this.uxService.loading();
    return this.http.get<any>(url).pipe(
      catchError((err) => {
        this.toastService.error('Something went wrong', 'Please contact your recruiter for assistance.');
        return of(null);
      }),
      finalize(() => this.uxService.loading(false)),
    );
  }

  getAtsResumeAdapter(customerId: number | string): Observable<any> {
    const url = `${environment.php_host}/api/jobs/atsresumeadapter/${customerId}`;
    return this.http.get<any>(url);
  }

  handleError(err: any, message: string) {
    // TODO: BTP -- handle/log errors to server
    console.error(err);
    // TODO: BTP -- need exact error message text
    this.toastService.error('Something went wrong', message);
    return throwError(() => err);
  }
}
