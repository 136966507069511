import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/profile-ng/environments/environment';
import { Cacheable } from 'ts-cacheable';
import { CompanyService } from '../../shared/services/company.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configs: any[];

  constructor(private _httpClient: HttpClient, private sessionService: SessionService, private companyService: CompanyService) {}

  @Cacheable({ maxAge: 1000 * 60 * 60 })
  public getConfigs(): Observable<any> {
    const url = `${environment.applicant_core_api_host}/api/v1/customerContext/${this.companyService.customerId}`;
    return this._httpClient.get<any>(url).pipe(
      tap((response: any) => (this.configs = response?.configs)),
      catchError((error) => throwError(() => error)),
    );
  }

  public getConfigValue(configKey: string): string {
    return this.configs?.find((config) => config.key === configKey)?.value;
  }

  public getConfigBooleanOrDefault(configKey: string, defaultConfigValue: boolean = false): boolean {
    if (!this.configs?.length) return defaultConfigValue;

    const val = this.configs.find((config) => config.key === configKey)?.value;

    if (val == null) {
      // Null or undefined
      return defaultConfigValue;
    } else if (val === true || val === 1 || val.toUpperCase() === 'TRUE') {
      return true;
    } else if (val === false || val === 0 || val.toUpperCase() === 'FALSE') {
      return false;
    }

    return defaultConfigValue;
  }
}
