import { JobsQueryResponse } from '../models/jobs-query.model';

export const mockJobResponse: JobsQueryResponse = {
  filterData: {
    categories: null,
    employmentTypes: ['Full Time', 'Contract', 'Part Time'],
    industries: null,
    skills: null,
    specialties: null,
  },
  data: [
    {
      name: 'Welcome card',
      jobId: 'ES-9385036',
      desc: 'Help maintain stock inventory, prepare packages and clean facility. Please wear long sleeves, pants, and hand-toed shoes.',
      isNew: false,
      isSaved: false,
      jobCity: 'Boston',
      jobState: 'MA',
      payRate: '25 p/h',
      startDate: '7/28',
      hours: '6:00am - 2:00pm',
      jobType: 'Full Time',
    },
    {
      name: 'Welcome card 2',
      jobId: 'ES-9385037',
      desc: `Veniam eiusmod pariatur ut laboris. Ipsum commodo sint elit exercitation labore ad laborum et id sit sunt veniam do. Aliqua esse ea excepteur id ipsum ut aliquip ullamco eu. Nostrud minim duis labore fugiat cillum deserunt culpa. Cupidatat aliqua Lorem ut esse proident sit minim magna incididunt ea nisi ullamco. Ullamco sint quis nulla ipsum aliquip voluptate non est labore.
    Occaecat reprehenderit laborum dolor mollit anim culpa qui est et consequat mollit mollit excepteur. Sunt aliquip occaecat laborum Lorem cillum veniam reprehenderit sint ea in aute duis proident et. Ullamco pariatur quis ad exercitation velit nulla Lorem.

    Ad aliqua consequat elit occaecat Lorem amet in excepteur ut Lorem veniam irure incididunt. Occaecat labore veniam id reprehenderit culpa dolore sunt non nisi veniam. Ad ullamco magna et minim voluptate dolor nulla elit sunt cupidatat pariatur ipsum. Ex eiusmod eu cillum culpa eu eu.
    Do elit voluptate cupidatat id laborum dolore eu adipisicing. Lorem dolore elit dolor veniam magna eiusmod magna qui excepteur esse reprehenderit. Cupidatat dolor cupidatat et culpa. Magna cillum minim laborum cupidatat laboris ut cillum exercitation est. Commodo nostrud voluptate mollit cillum Lorem amet non cillum consectetur occaecat sit. Enim sint veniam id est nisi in est consectetur qui laborum labore anim.
    Cupidatat do nulla commodo cupidatat enim exercitation exercitation nostrud exercitation dolore veniam. Pariatur nisi proident ex deserunt amet. Quis excepteur irure eiusmod enim dolor nostrud. Quis aliqua consectetur labore ut in velit eiusmod Lorem ullamco et cillum.
    Excepteur dolor sit est ex labore dolor elit aliqua. Aliquip adipisicing est excepteur enim et quis officia. Incididunt exercitation pariatur consequat ullamco. Est sint amet tempor cillum sunt anim pariatur cupidatat consectetur ea cillum. Cillum labore nulla adipisicing consectetur nostrud id eiusmod do velit deserunt culpa incididunt.


    Culpa reprehenderit culpa cupidatat magna pariatur cupidatat Lorem magna cillum exercitation ullamco. Anim quis do esse aliquip aliquip veniam incididunt commodo proident pariatur duis labore eu minim. Ad sit enim veniam cupidatat minim ea. Voluptate excepteur aliquip ad cillum mollit qui dolore consectetur. Culpa ea eiusmod esse sunt enim aliquip id ex officia id dolor laboris nisi duis. Proident in anim ea do exercitation ullamco anim minim est cupidatat.
    Reprehenderit adipisicing incididunt excepteur enim occaecat cillum. Dolore minim magna fugiat velit mollit eu esse do duis exercitation. Velit aliqua velit esse amet sit. Consectetur voluptate qui nulla cillum exercitation. Proident sunt anim nulla enim cillum anim Lorem reprehenderit et ullamco. Aliquip minim qui ipsum ipsum tempor aliquip consequat eu nisi. Eiusmod aliquip irure in ullamco.
    Reprehenderit fugiat tempor sit excepteur irure ipsum velit incididunt ex Lorem aute laboris ut adipisicing. Dolore anim et pariatur aliqua fugiat aliqua. Aliquip sit commodo tempor fugiat in laborum consectetur excepteur et pariatur.`,
      isNew: true,
      isSaved: true,
      jobCity: 'New York',
      jobState: 'NY',
      payRate: '25 p/h',
      startDate: '7/28',
      hours: '6:00am - 2:00pm',
      jobType: 'Full Time',
    },
    {
      name: 'Welcome card 3',
      jobId: 'ES-9385038',
      desc: 'Help maintain stock inventory, prepare packages and clean facility. Please wear long sleeves, pants, and hand-toed shoes.',
      isNew: true,
      isSaved: false,
      jobCity: 'Newark',
      jobState: 'NJ',
      salary: '8000',
      startDate: '7/28',
      hours: '6:00am - 2:00pm',
      jobType: 'Full Time',
    },
    {
      name: 'Welcome card 4',
      jobId: 'ES-9385039',
      desc: 'Help maintain stock inventory, prepare packages and clean facility. Please wear long sleeves, pants, and hand-toed shoes.',
      isNew: true,
      isSaved: false,
      jobCity: 'Austin',
      jobState: 'TX',
      salary: '6000',
      startDate: '7/28',
      hours: '6:00am - 2:00pm',
    },
    {
      name: 'Welcome card 5',
      jobId: 'ES-9385040',
      desc: 'Help maintain stock inventory, prepare packages and clean facility. Please wear long sleeves, pants, and hand-toed shoes.',
      isNew: true,
      isSaved: false,
      jobCity: 'Austin',
      jobState: 'TX',
      salary: '6000',
      startDate: '7/28',
      hours: '6:00am - 2:00pm',
      jobType: 'Full Time',
    },
    {
      name: 'Welcome card 6',
      jobId: 'ES-9385041',
      desc: 'Help maintain stock inventory, prepare packages and clean facility. Please wear long sleeves, pants, and hand-toed shoes.',
      isNew: true,
      isSaved: false,
      jobCity: 'Austin',
      jobState: 'TX',
      salary: '6000',
      startDate: '7/28',
      hours: '6:00am - 2:00pm',
      jobType: 'Full Time',
    },
    {
      name: 'Welcome card 7',
      jobId: 'ES-9385042',
      desc: 'Help maintain stock inventory, prepare packages and clean facility. Please wear long sleeves, pants, and hand-toed shoes.',
      isNew: true,
      isSaved: false,
      jobCity: 'Austin',
      jobState: 'TX',
      salary: '6000',
      startDate: '7/28',
      hours: '6:00am - 2:00pm',
      jobType: 'Full Time',
    },
  ],
  totalCount: 7,
  page: 1,
  pageSize: 1,
  offset: 0,
};
