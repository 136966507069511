import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { CompanyService } from 'src/profile-ng/app/shared/services/company.service';
import { environment } from 'src/profile-ng/environments/environment';
import { AuthService } from '../../services/auth.service';
import { SessionService } from '../../services/session.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private sessionService: SessionService, private companyService: CompanyService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.cloneWithHeaders(req);
    return next.handle(req).pipe(
      catchError((err) => {
        this.logoutIfUnauthorized(err);
        return throwError(err);
      }),
    );
  }

  cloneWithHeaders(req: HttpRequest<any>): HttpRequest<any> {
    const reqUrl = new URL(req.url);
    const accessToken = this.sessionService.profileAccessToken || [];
    let modRequest: HttpRequest<any>;
    let modHeaders: HttpHeaders = req.headers;
    if (reqUrl.origin === environment.php_host) modHeaders = req.headers.set('accesstoken', accessToken);
    if (reqUrl.origin === environment.applicant_core_api_host || reqUrl.origin === environment.customer_core_api_host)
      modHeaders = req.headers.set('Authorization', `Basic ${accessToken}`).set('X-Requested-By-Party', 'Applicant');
    modRequest = req.clone({ headers: modHeaders });
    return modRequest;
  }

  logoutIfUnauthorized(error: HttpErrorResponse) {
    if (error.status === 403 || error.status === 401) {
      this.authService.logout(this.companyService.customerCode, this.companyService.locationCode);
    }
  }
}
