export class AppUrls {
  // Top Level Routes
  static relPathToAppModule = `:customerCode/:locationCode/btp`;
  static relPathToLegacyModule = ``;

  // App Module Routes
  static relPathToHome = `home`;
  static relPathToJobs = `jobs`;
  static relPathToTasks = `tasks`;
  static relPathToTimeAndExpense = `timeandexpense`;
  static relPathToProfile = `profile`;
  static relPathToJobPreferences = `job-preferences`;

  // Profile Routes
  static relPathToStart = 'start';
  static relPathToEduHistory = 'edu-history';
  static relPathToBasicInfo = 'basic-info';
  static relPathToWorkHistory = 'work-history';
  static relPathToTaxExemption = 'tax-exemption';
  static relPathToCredentials = 'credentials';
}
