import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionsDialogData, DialogAction } from '../../dialogs.model';

@Component({
  selector: `bh-actions-dialog`,
  templateUrl: `./actions-dialog.component.html`,
  styleUrls: [`./actions-dialog.component.scss`],
})
export class ActionsDialogComponent {
  constructor(private dialogRef: MatDialogRef<ActionsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ActionsDialogData) {}

  triggerAction(action: DialogAction): void {
    this.dialogRef.close(action.name);
  }
}
