<ul class="task-names">
  <ng-container *ngFor="let item of row.items">
    <li>
      {{ item.name }}
    </li>
  </ng-container>

  <ng-container *ngIf="!row.items">
    <li>
      {{ row.name }}
    </li>
  </ng-container>
</ul>
