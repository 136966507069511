import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Customer } from '../models/customer.model';
import { CustomI18n } from '../models/i18n.model';
import { CompanyService } from '../services/company.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerResolver implements Resolve<Customer> {
  constructor(private companyService: CompanyService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Customer> {
    return this.companyService.getCustomer();
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomerI18nResolver implements Resolve<CustomI18n> {
  constructor(private companyService: CompanyService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<CustomI18n> {
    return this.companyService.getCustomDateFormat();
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomerBrandingResolver implements Resolve<any> {
  constructor(private companyService: CompanyService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.companyService.getBrandingColors();
  }
}
