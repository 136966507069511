import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'profile-complete-profile-card',
  templateUrl: './complete-profile-card.component.html',
  styleUrls: ['./complete-profile-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompleteProfileCardComponent {
  @Input() completenessPercentage: number;
  @Input() incompleteStepsCount: number;
  @Input() profileStepsCount: number;

  @Output() navigateToProfileEvent = new EventEmitter<any>();

  navigateToProfileSection() {
    this.navigateToProfileEvent.emit();
  }
}
