import { BtpProgress } from '../models/btp-progress.model';

export type BtpProgressKey = 'upload' | 'save';

export const BtpProgressConst: {
  [key in BtpProgressKey]: BtpProgress;
} = {
  upload: {
    start: 'Uploading...',
    finish: 'Uploaded',
    toastSuccess: 'Uploaded file',
    toastError: 'Try uploading the file again later',
  },
  save: {
    start: 'Saving...',
    finish: 'Saved',
    toastSuccess: 'Saved your changes',
    toastError: 'Try again later',
  },
};
