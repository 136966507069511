import moment from 'moment';

export class Helper {
  static formatDate = (date: Date, formatString: string): string => {
    return moment(date).format(formatString);
  };

  static validateIntlPhoneNumber(phoneNumber: string): boolean {
    return window['intlTelInputUtils'].isValidNumber(phoneNumber);
  }
}
