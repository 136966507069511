import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sentencecase' })
export class SentenceCasePipe implements PipeTransform {
  transform(sentence: string, ...args: unknown[]): string {
    return sentence[0].toUpperCase() + sentence.substring(1);

    // TRUE sentence case:
    // return sentence[0].toUpperCase() + sentence.substring(1).toLowerCase();
  }
}
