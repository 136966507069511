import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

class task {
  name: any;
}

@Component({
  selector: 'able-task-name',
  templateUrl: './task-name.component.html',
  styleUrls: ['./task-name.component.scss'],
})
export class TaskNameComponent implements OnChanges {
  @Input() row: any;

  name: string = '';

  buildName(row: any) {
    if (!row.items) {
      this.name = row.name;
      return;
    }

    let items = row.items.map((i: task) => {
      return i.name;
    });
    this.name = items.join('\n');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['row'] && changes['row'].currentValue) {
      this.row = changes['row'].currentValue;
      this.buildName(this.row);
    }
  }
}
