import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Observable, Subject, tap } from 'rxjs';
import { distinctUntilChanged, map, pairwise, share, takeUntil, throttleTime } from 'rxjs/operators';
import { ApplicantService } from 'src/profile-ng/app/shared/services/applicant.service';
import { CompanyService } from '../../../shared/services/company.service';
import { TranslationService } from '../../services/translation.service';
import { UxService } from '../../services/ux.service';

@Component({
  selector: 'profile-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('profileHeader') headerRef: ElementRef;

  logo$: Observable<string>;
  languages$: Observable<any>;
  headerHeight = 56;

  destroy = new Subject();

  private customerCode: string;
  private locationCode: string;
  public activeLanguage$: Observable<string>;
  public menuStatus = false;

  constructor(
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router,
    public uxService: UxService,
    private translationService: TranslationService,
    private applicantService: ApplicantService,
  ) {}

  ngOnInit() {
    fromEvent(document, 'scroll')
      .pipe(
        throttleTime(10),
        map((_) => window.scrollY),
        pairwise(),
        map(([y1, y2]) => (y2 < this.headerHeight ? y2 : y2 > y1 ? 'down' : 'up')),
        distinctUntilChanged(),
        tap((x) =>
          window.scrollY > this.headerHeight && x === 'down'
            ? this.headerRef.nativeElement.classList.add('scroll-hide')
            : this.headerRef.nativeElement.classList.remove('scroll-hide'),
        ),
        share(),
        takeUntil(this.destroy),
      )
      .subscribe();

    this.route.paramMap.pipe(takeUntil(this.destroy)).subscribe((params) => {
      this.customerCode = params.get('customerCode');
      this.locationCode = params.get('locationCode');
    });

    this.logo$ = this.companyService.getCompanyLogo();
    this.languages$ = this.translationService.languageOptions$;
    this.activeLanguage$ = this.translationService.activeLanguage$;
    this.applicantService.getApplicantDetails().subscribe((applicantDetails) => {
      this.translationService.setProfileLanguage(applicantDetails.language);
    });
  }

  onLanguageSelect(item: any): void {
    this.translationService.setProfileLanguage(item.value);
    this.applicantService.applicantDetails.language = item.value;
    this.applicantService.updateApplicantShallow(this.applicantService.applicantDetails).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }

  public toMyProfile(): void {
    this.router.navigate([`${this.customerCode}/${this.locationCode}/profile`]);
  }
}
