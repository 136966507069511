import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
})
export class FilterByPipe<T> implements PipeTransform {
  transform(array: T[], key: keyof T, value: T[keyof T]): T[] {
    if (!Array.isArray(array) || !key || value === undefined) {
      return array;
    }

    return array.filter(item => item[key] === value);
  }
}
