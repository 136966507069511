import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AngularJSResourceService } from 'src/profile-ng/app/core/services/angular-js-resource.service';

@Component({
  selector: 'profile-task-action',
  templateUrl: './task-action.component.html',
  styleUrls: ['./task-action.component.scss'],
})
export class TaskActionComponent implements OnChanges {
  @Input() item: any;
  @Input() disabled: boolean = false;

  Session: any;
  actionLabel: string = 'Download Front';
  actionBackLabel: string = 'Download Back';

  constructor(private ngService: AngularJSResourceService) {
    this.Session = this.ngService?.getResource('Session');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item'] && changes['item'].currentValue) {
      this.item = changes['item'].currentValue;
      this.actionLabel = this.item.secondary_file ? 'Download Front' : 'Download';
    } else if (changes['disabled'] && changes['disabled'].currentValue) {
      this.disabled = changes['disabled'].currentValue;
    }
  }

  goToAction(item: any) {
    window.open(item.url, '_blank');
    return false;
  }
}
