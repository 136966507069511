import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AngularJSResourceService } from 'src/profile-ng/app/core/services/angular-js-resource.service';

@Component({
  selector: 'profile-task-table',
  templateUrl: './task-table.component.html',
  styleUrls: ['./task-table.component.scss'],
})
export class TaskTableComponent implements OnChanges, OnInit {
  @Input() data: any;
  @Input() showStatus: boolean = false;
  @Input() isMobile: boolean = false;

  nameColumnHeader: string = '';
  statusColumnHeader: string = '';
  actionColumnHeader: string = '';
  loadingMessage: string = '';
  rejectedReason: string = '';
  completedDateColumnHeader: string = '';
  Session: any;

  constructor(private ngService: AngularJSResourceService) {
    this.Session = this.ngService?.getResource('Session');
  }

  ngOnInit(): void {
    this.nameColumnHeader = this.Session.translations['task_list_column_header_name'] || 'Name';
    this.statusColumnHeader = this.Session.translations['task_list_column_header_status'] || 'Status';
    this.actionColumnHeader = this.Session.translations['task_list_column_header_action'] || 'Action';
    this.loadingMessage = this.Session.translations['task_list_loading_message'] || 'Loading Tasks...';
    this.rejectedReason = this.Session.translations['task_list_status_rejected_reason'] || 'Rejection Reason';
    this.completedDateColumnHeader = this.Session.translations['task_list_completed_date'] || 'Completed Date';
  }

  getCompletedDateFormated(completedDate: string) {
    return completedDate?.split('T')[0];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.data = changes['data'].currentValue;
    } else if (changes['showStatus'] && changes['showStatus'].currentValue) {
      this.showStatus = changes['showStatus'].currentValue;
    } else if (changes['isMobile'] && changes['isMobile'].currentValue) {
      this.isMobile = changes['isMobile'].currentValue;
    }
  }
}
