import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationStatus } from '../../core/constants/application-status.enum';
import { SessionService } from '../../core/services/session.service';
import { TalentPlatformSettingsService } from '../../core/services/talent-platform-settings.service';
import { Applicant } from '../../shared/models/applicant.model';
import { ProfileStepDefinition } from '../../shared/models/profile-step-definition.model';
import { Task, TaskType } from '../../shared/models/tasks.model';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  constructor(private tpsService: TalentPlatformSettingsService, private sessionService: SessionService, private router: Router) {}

  getProfileSteps(showPrescreening: boolean): ProfileStepDefinition[] {
    return [
      {
        state: 'apply/basic-info',
        translationKey: 'app_step_basic',
        complete: false,
        status: ApplicationStatus.BASICINFO,
        active: () => {
          return true;
        },
        isActive: false,
      },
      {
        state: 'apply/tax-exemption',
        translationKey: 'app_step_tax_exempt',
        complete: false,
        status: ApplicationStatus.TAXEXEMPT,
        active: () => {
          return this.tpsService.getConfigFlag('EnablePresceeningTaxExemptions');
        },
        isActive: false,
      },
      {
        state: 'apply/job-prefs',
        translationKey: 'app_step_prefs',
        complete: false,
        status: ApplicationStatus.JOBPREFS,
        active: () => {
          return !this.tpsService.getConfigString('DisableJobPreferences');
        },
        isActive: false,
      },
      {
        state: 'apply/edu-history',
        translationKey: 'app_step_edu',
        complete: false,
        status: ApplicationStatus.EDUHIST,
        active: () => {
          return !this.tpsService.getConfigString('HideEduFromApp');
        },
        isActive: false,
      },
      {
        state: 'apply/work-history',
        translationKey: 'app_step_work',
        complete: false,
        status: ApplicationStatus.WORKHIST,
        active: () => {
          return !this.tpsService.getConfigString('HideWorkFromApp');
        },
        isActive: false,
      },
      {
        state: 'apply/skills',
        translationKey: 'skills-checklist',
        complete: false,
        status: ApplicationStatus.SKILLCHECKLIST,
        active: () => {
          return this.tpsService.getConfigFlag('EnableSkillChecklists') && !this.tpsService.getConfigFlag('DisableProfileSkillsChecklists');
        },
        isActive: false,
      },
      {
        state: 'apply/tax-credit',
        translationKey: 'app_step_tax_credit',
        complete: false,
        status: ApplicationStatus.TAXCREDIT,
        active: () => {
          return this.tpsService.getConfigFlag('EnableTaxCreditOnApplication');
        },
        isActive: false,
      },
      {
        state: 'prescreen',
        translationKey: 'app_step_additional_info',
        complete: false,
        status: ApplicationStatus.PRESCREEN,
        active: () => {
          return showPrescreening;
        },
        isActive: false,
      },
      {
        state: 'apply/complete',
        translationKey: 'app_step_confirm',
        complete: false,
        status: ApplicationStatus.COMPLETE,
        active: () => {
          return true;
        },
        isActive: false,
      },
    ];
  }

  launchOpenTask(task: Task, route: ActivatedRoute, currentSteps, applicant: Applicant) {
    switch (task.taskType) {
      case TaskType.Form:
      case TaskType.CustomPackage:
      case TaskType.I9Correction:
      case TaskType.Package: {
        let url = 'onboarding/preliminary-info';

        if (this.tpsService.getConfigFlag('new_sig_request_for_isolated_signing') && task.items.every((t) => t.isolatedSigning)) {
          url = 'isolated/forms';
        } else if (this.sessionService.applicantPrelimInfoFilledOut || applicant.userId) {
          const embeddedIntegration = task.items.every((t) => t.templateTypeId === 18);
          url = embeddedIntegration ? 'embedded-integration/forms' : 'onboarding/forms';
        }

        this.router.navigate([`../${url}/${task.sigRequestId}`], {
          relativeTo: route,
        });
        break;
      }
      case TaskType.Credential:
        this.sessionService.returnToTaskListOnAllCredentialsUploaded(true);
        this.router.navigate([`../profile/credentials/${task.applicantCredentialId}`], {
          relativeTo: route,
        });
        break;
      case TaskType.SkillChecklist:
        this.sessionService.returnWhere('my-profile');
        this.router.navigate([`../profile/skills/checklist/${task.skillChecklistId}/${task.applicantSkillChecklistId}`], {
          relativeTo: route,
        });
        break;
      case TaskType.StartProfile:
      case TaskType.CompleteProfile:
        this.navigateToProfileSection(currentSteps, route);
        break;
      default:
        return;
    }
  }

  navigateToProfileSection = (steps, route: ActivatedRoute) => {
    const nextStep = this.getNextStep(steps);

    if (nextStep === undefined) {
      this.router.navigate(['../profile/start'], {
        relativeTo: route,
      });
    } else if (nextStep.state === 'prescreen') {
      this.router.navigate(['../apply/complete'], {
        relativeTo: route,
      });
    } else {
      this.router.navigate([`../${nextStep.state}`], {
        relativeTo: route,
      });
    }
  };

  getNextStep = function (steps) {
    for (let i = 0; i < steps.length; i++) {
      if (!steps[i].complete) {
        return steps[i];
      }
    }
  };
}
