import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Applicant, ApplicantDetails } from '../models/applicant.model';
import { ApplicantService } from '../services/applicant.service';
import { ApplicantProperty } from '../models/applicant-property.model';

@Injectable({
  providedIn: 'root',
})
export class ApplicantResolver implements Resolve<Applicant> {
  constructor(private applicantService: ApplicantService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Applicant> {
    return this.applicantService.getApplicant();
  }
}

@Injectable({
  providedIn: 'root',
})
export class ApplicantDetailsResolver implements Resolve<ApplicantDetails> {
  constructor(private applicantService: ApplicantService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ApplicantDetails> {
    return this.applicantService.getApplicantDetails();
  }
}

@Injectable({
  providedIn: 'root',
})
export class ApplicantPropertiesResolver implements Resolve<ApplicantProperty[]> {
  constructor(private applicantService: ApplicantService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ApplicantProperty[]> {
    return this.applicantService.getApplicantProperties();
  }
}
