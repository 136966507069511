import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Toast, ToastService } from './../../services/toast.service';

@Component({
  selector: 'profile-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent {
  @Input() data: Toast;
  @Input() index: number;

  constructor(private toastService: ToastService) {}

  onClose() {
    this.toastService.remove(this.index);
  }
}
