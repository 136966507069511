import { catchError, map, Observable, of, startWith, tap } from 'rxjs';
import { BtpProgress } from '../../core/models/btp-progress.model';
import { ToastService } from '../../core/services/toast.service';

export function btpProgress(status: BtpProgress, toastService: ToastService): (source: Observable<any>) => Observable<any> {
  return (source: Observable<any>) => {
    return source.pipe(
      startWith({ pending: true, status: status.start }),
      map((response) => {
        return !response || !('pending' in response) ? { pending: false, status: status.finish } : response;
      }),
      tap((result) => {
        if (result?.status === status.finish) {
          toastService.success('Success', status.toastSuccess);
        }
      }),
      catchError((err) => {
        toastService.error('Something went wrong', status.toastError);
        return of(null);
      }),
    );
  };
}
