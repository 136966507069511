import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LogOutOfLegacyApiResponse } from '../../shared/models/api.model';
import {LocalStorageService} from "../../localStorage.service";

@Injectable({
  providedIn: 'root',
})
export class LegacyApiService {
  constructor(private http: HttpClient, private localStorage: LocalStorageService) {}

  logOutOfLegacyApp = this.http.post<LogOutOfLegacyApiResponse>(`${environment.php_host}/api/v2/index/removetoken`, {
    access_token: this.localStorage.getItem('profile-accessToken'),
  });
}
