<header id="profile-header" #profileHeader class="btp-shadow-none" *ngIf="uxService.navigationVisible">
  <a (click)="toMyProfile()" class="header-link">
    <ng-container *ngIf="logo$ | async as logoSrc">
      <img [src]="logoSrc" class="cust-logo" />
    </ng-container>
  </a>

  <div class="language-selector header-link" *ngIf="activeLanguage$ | async as activeLanguage">
    <button
      mat-icon-button
      [matMenuTriggerFor]="clickmenu"
      aria-label="language selector"
      [ngClass]="menu.menuOpen ? 'language-active' : 'language-inactive'"
      #menu="matMenuTrigger"
      (menuOpened)="menuStatus = true"
      (menuClosed)="menuStatus = false"
    >
      <mat-icon class="language-icon">language</mat-icon>
    </button>
    <mat-menu #clickmenu="matMenu">
      <button mat-menu-item *ngFor="let lang of languages$ | async" (click)="onLanguageSelect(lang)">
        <span [ngClass]="lang.value === activeLanguage ? 'language-active' : 'language-inactive'"> {{ lang.label }}</span>
      </button>
    </mat-menu>
  </div>
</header>
