export interface TasksResponse {
  outstandingTasks: number;
  profileCompletionStatus: string;
  currentPage: number;
  data: Task[];
}

export interface CompletedTasksResponse {
  completedTasks: number;
  currentPage: number;
  data: Task[];
}

export interface Task {
  workflowId: number;
  name: string;
  taskType: TaskType;
  packageId: number;
  sigRequestId: number;
  applicantCredentialId: number;
  applicantSkillChecklistId: number;
  skillChecklistId: number;
  workflowActionId: number;
  completedDate: string;
  expirationDate: string;
  expiresIn?: number;
  status: {
    title: string;
    message: string;
  };
  items: TaskItem[];
}

export interface TaskItem {
  name: string;
  itemType: TaskItemType;
  isolatedSigning: boolean;
  templateTypeId: number;
  file?: { filename: string; url: string };
  secondaryFile?: { filename: string; url: string };
  completedDate: string;
  status: {
    title: string;
    message: string;
  };
  downloadable: boolean;
}

export enum TaskType {
  Form = 'Form',
  CustomPackage = 'Custom Package',
  Credential = 'Credential',
  SkillChecklist = 'Skill Checklist',
  Package = 'Package',
  StartProfile = 'Start Profile',
  CompleteProfile = 'Complete Profile',
  I9Correction = 'I-9 Correction',
}

export enum TaskTypeTranslationKey {
  Form = 'form',
  CustomPackage = 'custom_package',
  Credential = 'credential',
  SkillChecklist = 'skill_checklists_checklist',
  Package = 'package',
  StartProfile = 'start_profile',
  CompleteProfile = 'complete_profile',
  I9Correction = 'i9_correction',
}

export enum TaskItemType {
  Unknown = 'UNKNOWN',
  Form = 'FORM',
  ScreeningOrderItem = 'SCREENING_ORDER_ITEM',
  Credential = 'CREDENTIAL',
  SkillChecklist = 'SKILL_CHECKLIST',
}

export type TaskCategory = 'open' | 'completed';
